import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import validationSchema from "./validation/validationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";

import modules from "./constants/modules";
import paymentMethodsItems from "./constants/paymentMethods";
import moduleQuestionsItems from "./constants/moduleQuestions";

import ButtonVolumetryForm from "../../contexts/ButtonVolumetryForm";
import StepVolumetryForm from "../../contexts/StepVolumetryForm";

import Modules from "./components/Modules";
import Companies from "./components/companies/Companies";
import Stepper from "./components/Stepper";
import Questions from "./components/questions/Questions";
import Acknowledgment from "./components/Acknowledgment";

import VolumetryFormRepository from "./repositories/VolumetryFormRepository";
import PaymentMethodsVolumetryForm from "../../contexts/PaymentMethodsVolumetryForm";

export default function VolumetryForm({ screenSize }) {
  const repository = new VolumetryFormRepository();

  const locationPath = useLocation();

  const navigate = useNavigate();

  const { token } = useParams();

  const [dataRadioButtonBankSlip, setDateRadioButtonBankSlip] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const { currentStep, selectedModules, setSelectedModules, selectedPaymentMethods } = useContext(StepVolumetryForm);
  const { setQuestionsModules, formsCompleted, setFormsCompleted, setShareLink } = useContext(ButtonVolumetryForm);
  const { setCheckBankSlip, setCheckPix, setCheckCard, setCheckDirectDebit } = useContext(PaymentMethodsVolumetryForm);

  const methods = useForm({
    defaultValues: {
      email: "",
      companies: [],
      modules: {},
      questions: {
        billing: {
          paymentMethods: {},
          default: "",
          bankSlip: [],
          pix: [],
          card: [],
          directDebit: [],
        },
        billingInstructions: [],
        payment: [],
        statementReconciliation: [],
        payroll: [],
      },
    },
    mode: "onTouched",
    resolver: yupResolver(validationSchema(selectedModules, selectedPaymentMethods, locationPath)),
  });

  const paymentMethodsContext = methods.watch("paymentMethods");
  let selectedModulesContext = methods.watch("modules");

  async function validateForm(fieldNames) {
    try {
      const isValid = await Promise.all(
        fieldNames.map(async (fieldName) => {
          if (fieldName === "billing") {
            const result = await validateBillingModule(selectedPaymentMethods);

            return result;
          }

          return methods.trigger(fieldName);
        })
      );

      return isValid.every((valid) => valid);
    } catch (error) {
      console.error("Form validation failed:", error);

      return false;
    }
  }

  async function validateBillingModule(selectedPaymentMethods) {
    try {
      const isValid = await Promise.all(
        selectedPaymentMethods.map(async (paymentMethod) => {
          return methods.trigger(`billing.${paymentMethod}`);
        })
      );
      if (isValid.every((valid) => valid)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Billing Module - Form validation failed:", error);
      return false;
    }
  }

  async function moduleQuestionBuilder() {
    const selectedModules = Object.values(selectedModulesContext)
      .map((isSelected, index) => (isSelected ? modules[index].module : null))
      .filter(Boolean);

    const questionsWithModules = selectedModules.map((module) => {
      const moduleQuestionsList = moduleQuestionsItems[module];
      return { moduleName: module, questions: moduleQuestionsList };
    });

    const questionsModules = questionsWithModules.filter((module) => module.questions);

    setSelectedModules(selectedModules);
    setQuestionsModules(questionsModules);

    return;
  }

  async function getVolumetryForm(token) {
    try {
      setLoadingData(true);

      const data = await repository.getOne(token);

      if (data.status === "completed" && !locationPath.pathname.startsWith("/novo-formulario/interno")) {
        navigate("/novo-formulario/token-expirado");
      }

      let paymentMethodsSelected = {};

      methods.setValue("email", data.email);
      methods.setValue("companies", data.companies);
      methods.setValue("modules", data.modules);
      methods.setValue("paymentMethods", data.questions.billing.paymentMethods);
      methods.setValue("billing.default", data.questions.billing.default);
      methods.setValue("billing.bankSlip", data.questions.billing.bankSlip);
      methods.setValue("billing.pix", data.questions.billing.pix);
      methods.setValue("billing.card", data.questions.billing.card);
      methods.setValue("billing.directDebit", data.questions.billing.directDebit);
      methods.setValue("billingInstructions", data.questions.billingInstructions);
      methods.setValue("payment", data.questions.payment);
      methods.setValue("statementReconciliation", data.questions.statementReconciliation);
      methods.setValue("payroll", data.questions.payroll);

      setDateRadioButtonBankSlip(data.questions.billing.bankSlip.question3);

      for (const paymentMethodIndex in data.questions.billing.paymentMethods) {
        if (data.questions.billing.paymentMethods.hasOwnProperty(paymentMethodIndex)) {
          const paymentMethodName = paymentMethodsItems[paymentMethodIndex].method;
          const paymentMethodValue = data.questions.billing.paymentMethods[paymentMethodIndex];
          paymentMethodsSelected[paymentMethodName] = paymentMethodValue;
        }
      }

      for (const paymentMethod in paymentMethodsSelected) {
        if (paymentMethodsSelected[paymentMethod] === true) {
          switch (paymentMethod) {
            case "bankSlip":
              setCheckBankSlip(true);
              break;
            case "pix":
              setCheckPix(true);
              break;
            case "card":
              setCheckCard(true);
              break;
            case "directDebit":
              setCheckDirectDebit(true);
              break;
            default:
              break;
          }
        }
      }

      setLoadingData(false);
    } catch (error) {
      console.error("getVolumetryForm :: ", error);
    }
  }

  async function submitForm(event) {
    event.preventDefault();

    const isValid = await validateForm(selectedModules);

    if (isValid) {
      methods.handleSubmit(save)();
    } else {
      return;
    }
  }

  async function save(value) {
    startLoading();

    const path = locationPath.pathname;
    let modulesSelected = {};
    let paymentMethodsSelected = {};

    for (const moduleIndex in value.modules) {
      if (value.modules.hasOwnProperty(moduleIndex)) {
        const moduleName = modules[moduleIndex].module;
        const moduleValue = value.modules[moduleIndex];
        modulesSelected[moduleName] = moduleValue;
      }
    }

    if (value.paymentMethods) {
      if (!token) {
        value.paymentMethods.forEach((isSelected, index) => {
          paymentMethodsSelected[paymentMethodsItems[index].method] = isSelected;
        });
      } else {
        for (const paymentMethodIndex in value.paymentMethods) {
          if (value.paymentMethods.hasOwnProperty(paymentMethodIndex)) {
            const paymentMethodName = modules[paymentMethodIndex].module;
            const paymentMethodValue = value.paymentMethods[paymentMethodIndex];
            paymentMethodsSelected[paymentMethodName] = paymentMethodValue;
          }
        }
      }
    } else {
      paymentMethodsSelected = {
        bankSlip: false,
        pix: false,
        card: false,
        directDebit: false,
      };
    }

    const data = {
      email: value.email,
      companies: value.companies,
      modules: modulesSelected,
      questions: {
        billing: {
          paymentMethods: paymentMethodsSelected,
          default: value.billing?.default ?? "",
          bankSlip: value.billing?.bankSlip ?? [],
          pix: value.billing?.pix ?? [],
          card: value.billing?.card ?? [],
          directDebit: value.billing?.directDebit ?? [],
        },
        billingInstructions: value.billingInstructions ?? [],
        payment: value.payment ?? [],
        statementReconciliation: value.statementReconciliation ?? [],
        payroll: value.payroll ?? [],
      },
    };

    if (!token) {
      const response = await repository.createOne(data, path);
      setShareLink(`https://inn.cash/novo-formulario/token/${response.token}`);
    } else {
      await repository.updateOne(token, data);
    }

    await stopLoading();

    setFormsCompleted(true);
  }

  function startLoading() {
    let loadingButtonSubmit = document.getElementById("form-loader-submit");
    let textButtonSubmit = document.getElementById("form-submit");
    loadingButtonSubmit.classList.remove("hidden");
    loadingButtonSubmit.classList.add("block");
    textButtonSubmit.classList.remove("block");
    textButtonSubmit.classList.add("hidden");
  }

  async function stopLoading() {
    let loadingButtonSubmit = document.getElementById("form-loader-submit");
    let textButtonSubmit = document.getElementById("form-submit");
    loadingButtonSubmit.classList.remove("block");
    loadingButtonSubmit.classList.add("hidden");
    textButtonSubmit.classList.remove("hidden");
    textButtonSubmit.classList.add("block");
  }

  function addModule() {
    selectedModulesContext = methods.watch("modules");
  }

  useEffect(() => {
    if (token) {
      getVolumetryForm(token);
      if (locationPath.pathname.startsWith("/novo-formulario/interno")) {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="mt-[81px]">
        <div className="bg-inncash bg-cover bg-no-repeat lg:h-[585px] md:h-[420px] h-[420px]" />
        <div className="flex flex-col items-center justify-center mt-[-340px] lg:mt-[-650px]">
          {screenSize <= 768 ? (
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-white text-3xl max-[380px]:text-[22px] lg:mt-[130px]">Gostaríamos de saber mais</h1>
              <h1 className="text-white text-3xl max-[380px]:text-[22px] lg:mt-[130px] lg:mb-[60px] mb-[60px]">
                sobre a <b>sua empresa</b>.
              </h1>
            </div>
          ) : (
            <h1 className="text-white text-3xl lg:mt-[130px] lg:mb-[60px] mb-[60px]">
              Gostaríamos de saber mais sobre a <b>sua empresa</b>.
            </h1>
          )}
          <div className="border-2 border-gray-70-inncash w-[400px] max-[380px]:w-[350px] md:w-[800px] lg:w-[1000px] bg-white rounded-3xl py-12">
            <FormProvider {...methods}>
              {loadingData ? (
                <div className="px-20 pb-[400px]" id="form-loader-submit">
                  <div className="mx-auto h-20 w-20 border-4 border-l-gray-200 border-r-gray-200 border-b-gray-200 border-t-blue-inncash animate-spin ease-linear rounded-full"></div>
                </div>
              ) : (
                <form onSubmit={methods.handleSubmit(save)}>
                  {currentStep === 1 && !formsCompleted && (
                    <div className="lg:px-10 px-3">
                      <div className="flex flex-col justify-center items-center mb-8">
                        <h2 className="font-bold text-xl max-[380px]:text-[17px] md:text-2xl">
                          Preencha o formulário abaixo com as
                        </h2>
                        <h2 className="font-bold text-xl max-[380px]:text-[17px] md:text-2xl">
                          informações referentes ao seu negócio.
                        </h2>
                      </div>
                      <Controller
                        name="email"
                        control={methods.control}
                        render={({ field }) => (
                          <>
                            <input
                              type="text"
                              name="email"
                              placeholder="Informe seu e-mail*"
                              className="placeholder:text-black placeholder:font-bold placeholder:text-MD border-2 border-gray-200-inncash rounded-full px-6 py-1 w-full"
                              {...field}
                            />
                            {methods.formState.errors.email && (
                              <span className="font-bold text-xs text-red-500 ml-6">
                                {methods.formState.errors.email.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                      <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
                        <h2 className="font-bold">Em quais empresas você gostaria de implementar o InnCash?*</h2>
                        {methods.formState.errors.companies && (
                          <span className="font-bold text-xs text-red-500">
                            {methods.formState.errors.companies.message}
                          </span>
                        )}
                        <div className="flex justify-center">
                          <Controller
                            name="companies"
                            control={methods.control}
                            render={({ field }) => <Companies ref={field.ref} {...field} />}
                          />
                        </div>
                      </div>
                      <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
                        <h2 className="font-bold">Selecione o(s) processo(s) que você deseja automatizar:</h2>
                        {methods.formState.errors.modules && (
                          <span className="font-bold text-xs text-red-500">
                            {methods.formState.errors.modules.message}
                          </span>
                        )}
                        <Modules
                          listModules={modules}
                          selectedModulesContext={selectedModulesContext}
                          control={methods.control}
                          addModule={addModule}
                        />
                      </div>
                    </div>
                  )}

                  {currentStep !== 1 && !formsCompleted && (
                    <div className="md:px-10 px-2">
                      <Questions
                        methods={methods}
                        control={methods.control}
                        paymentMethods={paymentMethodsItems}
                        paymentMethodsContext={paymentMethodsContext}
                        dataRadioButtonBankSlip={dataRadioButtonBankSlip}
                      />
                    </div>
                  )}

                  {formsCompleted && (
                    <div className="p-14">
                      <Acknowledgment />
                    </div>
                  )}

                  {!formsCompleted && (
                    <div className="flex flex-col">
                      <Stepper
                        moduleQuestionBuilder={moduleQuestionBuilder}
                        validateForm={validateForm}
                        handleSubmit={submitForm}
                        locationPath={locationPath}
                        errors={methods.formState.errors}
                      />
                    </div>
                  )}
                </form>
              )}
            </FormProvider>
          </div>
        </div>
      </section>
    </>
  );
}
