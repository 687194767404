import * as yup from "yup";

export default function validationSchema(selectedModules, selectedPaymentMethods, locationPath) {
  let modulesSelected = {};
  let paymentMethodsSelected = {};

  const modules = {
    billing: false,
    billingInstructions: false,
    payment: false,
    conciliationDDA: false,
    statementReconciliation: false,
    payroll: false,
  };

  const paymentMethods = {
    bankSlip: false,
    pix: false,
    card: false,
    directDebit: false,
  };

  for (const moduleName in modules) {
    if (selectedModules.includes(moduleName)) {
      modulesSelected[moduleName] = true;
    } else {
      modulesSelected[moduleName] = false;
    }
  }

  for (const paymentMethodName in paymentMethods) {
    if (selectedPaymentMethods.includes(paymentMethodName)) {
      paymentMethodsSelected[paymentMethodName] = true;
    } else {
      paymentMethodsSelected[paymentMethodName] = false;
    }
  }

  return yup.object({
    email:
      locationPath.pathname !== "/novo-formulario/interno"
        ? yup.string().required("Campo obrigatório").email("Insira um email válido")
        : yup.mixed().notRequired(),
    companies:
      locationPath.pathname !== "/novo-formulario/interno"
        ? yup.array().min(1, "Nenhuma empresa adiconada")
        : yup.mixed().notRequired(),
    modules: yup
      .object()
      .shape()
      .test({
        name: "testAnyOptionSelected",
        message: "Nenhuma opção selecionada",
        test: function (value) {
          const items = Object.values(value);
          return items.find((item) => item === true) !== undefined;
        },
      })
      .test({
        name: "testConciliationDDAAlone",
        message: "Conciliação de DDA não pode ser submetida individualmente",
        test: function (value) {
          const items = Object.values(value);
          const trueCount = items.filter((item) => item === true).length;

          if (trueCount === 1 && items[3] === true) {
            return false;
          }

          return true;
        },
      }),

    billing:
      modulesSelected.billing && locationPath.pathname !== "/novo-formulario/interno"
        ? yup.object().shape({
            default: yup.string().required("Este campo é obrigatório"),
            bankSlip:
              paymentMethodsSelected.bankSlip && locationPath.pathname !== "/novo-formulario/interno"
                ? yup.object().shape({
                    question1: yup.string().required("Este campo é obrigatório"),
                    question2: yup.string().required("Este campo é obrigatório"),
                    question3: yup.string().test("required", "Nenhuma opção selecionada", function (value) {
                      if (!value) {
                        return false;
                      }
                      return true;
                    }),
                  })
                : yup.mixed().notRequired(),
            pix:
              paymentMethodsSelected.pix && locationPath.pathname !== "/novo-formulario/interno"
                ? yup.object().shape({
                    question1: yup.string().required("Este campo é obrigatório"),
                    question2: yup.string().required("Este campo é obrigatório"),
                  })
                : yup.mixed().notRequired(),
            card:
              paymentMethodsSelected.card && locationPath.pathname !== "/novo-formulario/interno"
                ? yup.object().shape({
                    question1: yup.string().required("Este campo é obrigatório"),
                    question2: yup.string().required("Este campo é obrigatório"),
                  })
                : yup.mixed().notRequired(),
            directDebit:
              paymentMethodsSelected.directDebit && locationPath.pathname !== "/novo-formulario/interno"
                ? yup.object().shape({
                    question1: yup.string().required("Este campo é obrigatório"),
                    question2: yup.string().required("Este campo é obrigatório"),
                  })
                : yup.mixed().notRequired(),
          })
        : yup.mixed().notRequired(),

    billingInstructions:
      modulesSelected.billingInstructions && locationPath.pathname !== "/novo-formulario/interno"
        ? yup.object().shape({
            question1: yup.string().required("Este campo é obrigatório"),
            question2: yup.string().required("Este campo é obrigatório"),
            question3: yup.string().required("Este campo é obrigatório"),
          })
        : yup.mixed().notRequired(),

    payment:
      modulesSelected.payment && locationPath.pathname !== "/novo-formulario/interno"
        ? yup.object().shape({
            question1: yup.string().required("Este campo é obrigatório"),
            question2: yup.string().required("Este campo é obrigatório"),
            question3: yup.string().required("Este campo é obrigatório"),
          })
        : yup.mixed().notRequired(),

    statementReconciliation:
      modulesSelected.statementReconciliation && locationPath.pathname !== "/novo-formulario/interno"
        ? yup.object().shape({
            question1: yup.string().required("Este campo é obrigatório"),
            question2: yup.string().required("Este campo é obrigatório"),
          })
        : yup.mixed().notRequired(),

    payroll:
      modulesSelected.payroll && locationPath.pathname !== "/novo-formulario/interno"
        ? yup.object().shape({
            question1: yup.string().required("Este campo é obrigatório"),
            question2: yup.string().required("Este campo é obrigatório"),
            question3: yup.string().required("Este campo é obrigatório"),
          })
        : yup.mixed().notRequired(),
  });
}
