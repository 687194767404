import { useState } from "react";
import Modal from "react-modal";
import { MdClose, MdMinimize, MdAdd } from "react-icons/md";

import ContactForms from "../../components/ContactForms/ContactForms";

import notebookPixDesktop from "../../assets/img/notebook-pix-desktop.webp";
import notebookPixMobile from "../../assets/img/notebook-pix-mobile.webp";
import notebookBillingDesktop from "../../assets/img/notebook-billing-desktop.webp";
import notebookBillingMobile from "../../assets/img/notebook-billing-mobile.webp";

import videoBillingSolutions from "../../assets/thumbs/video-billing-solutions.webp";
import playButton from "../../assets/icons/play.svg";
import registerBilling from "../../assets/icons/register-billing.svg";
import fileHandling from "../../assets/icons/file-handling.svg";
import banksReturns from "../../assets/icons/bank-returns.svg";
import rejectShipments from "../../assets/icons/reject-shipments.svg";
import erpTitles from "../../assets/icons/erp-titles.svg";
import ticketSent from "../../assets/icons/ticket-sent.svg";
import printScreenBilling from "../../assets/img/print-screen-billing.webp";

import weTakeCare from "../../assets/icons/we-take-care.svg";
import weSendData from "../../assets/icons/we-send-data.svg";
import reconciledTransfer from "../../assets/icons/reconciled-transfer.svg";

export default function Billing({ modalStyle, screenSize }) {
  const [modalIsOpenVideoBillingSolutions, setModalIsOpenVideoBillingSolutions] = useState(false);
  const [sectionPix01IsOpen, setSectionPix01IsOpen] = useState(false);
  const [sectionPix02IsOpen, setSectionPix02IsOpen] = useState(false);
  const [sectionPix03IsOpen, setSectionPix03IsOpen] = useState(false);
  const [sectionPix04IsOpen, setSectionPix04IsOpen] = useState(false);

  const [sectionBilling01IsOpen, setSectionBilling01IsOpen] = useState(false);
  const [sectionBilling02IsOpen, setSectionBilling02IsOpen] = useState(false);
  const [sectionBilling03IsOpen, setSectionBilling03IsOpen] = useState(false);
  const [sectionBilling04IsOpen, setSectionBilling04IsOpen] = useState(false);
  const [sectionBilling05IsOpen, setSectionBilling05IsOpen] = useState(false);
  const [sectionBilling06IsOpen, setSectionBilling06IsOpen] = useState(false);

  function openSectionPix01() {
    setSectionPix01IsOpen(!sectionPix01IsOpen);
  }

  function openSectionPix02() {
    setSectionPix02IsOpen(!sectionPix02IsOpen);
  }

  function openSectionPix03() {
    setSectionPix03IsOpen(!sectionPix03IsOpen);
  }

  function openSectionPix04() {
    setSectionPix04IsOpen(!sectionPix04IsOpen);
  }

  function openSectionBilling01() {
    setSectionBilling01IsOpen(!sectionBilling01IsOpen);
  }

  function openSectionBilling02() {
    setSectionBilling02IsOpen(!sectionBilling02IsOpen);
  }

  function openSectionBilling03() {
    setSectionBilling03IsOpen(!sectionBilling03IsOpen);
  }

  function openSectionBilling04() {
    setSectionBilling04IsOpen(!sectionBilling04IsOpen);
  }

  function openSectionBilling05() {
    setSectionBilling05IsOpen(!sectionBilling05IsOpen);
  }

  function openSectionBilling06() {
    setSectionBilling06IsOpen(!sectionBilling06IsOpen);
  }

  function openModalVideoBillingSolutions() {
    setModalIsOpenVideoBillingSolutions(true);
  }

  function closeModalVideoBillingSolutions() {
    setModalIsOpenVideoBillingSolutions(false);
  }

  return (
    <>
      <section className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between md:py-20 py-10 items-center min-[1700px]:px-36 xl:px-20 lg:px-12 md:px-4 md:mt-20 mt-[81px]">
        <div className="flex flex-col md:items-start items-center text-white">
          <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">Soluções em</h1>
          <h1 className="font-bold text-4xl lg:text-6xl md:mb-7 mb-4 min-[1700px]:text-7xl">Cobranças</h1>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">O InnCash é um hub que integra o seu ERP</p>
          <p className="text-sm xl:text-lg mb-2 min-[1700px]:text-xl">com a instituição financeira da sua escolha.</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">Automatize as cobranças de sua empresa</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">com uma plataforma única para todos os</p>
          <p className="text-sm xl:text-lg md:mb-7 mb-6 min-[1700px]:text-xl">bancos e todos os meios de pagamento.</p>
          <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
            <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
          </button>
        </div>
        <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
          <img
            src={videoBillingSolutions}
            alt="Thumbnail vídeo do youtube soluções em cobranças Inncash"
            className="rounded-3xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
          />
          <img
            src={playButton}
            onClick={openModalVideoBillingSolutions}
            alt="Financeiro Eficiente"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
          />
        </span>
      </section>

      <section className="mt-5 md:mt-12">
        {screenSize <= 768 ? (
          <div className="font-bold text-lg flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Simplifique o seu processo</h1>
            <h1>de cobranças</h1>
          </div>
        ) : (
          <div className="md:flex justify-center mb-8">
            <h1 className="md:text-xl xl:text-2xl font-bold">Simplifique o seu processo de cobranças</h1>
          </div>
        )}
        <div className="text-lg md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Confira as nossas soluções para</h1>
          <h1>
            <b>cobranças</b> da sua empresa
          </h1>
        </div>

        <div className="md:flex items-center justify-center md:mt-8 mt-16">
          <div className="md:grid grid-cols-2 mx-3 md:w-[1200px] xl:ml-20">
            <div className="flex flex-col xl:flex-row items-center xl:justify-center xl:items-start text-sm">
              <img
                src={registerBilling}
                alt="Ícone azul com um cifrão dentro de uma engrenagem envolte de flechas"
                className="w-16 md:w-16 xl:mt-4"
              />
              <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[17px] xl:text-xl mb-5">
                  Você escolhe onde registrar sua cobrança
                </h2>
                <p>Como a geração da remessa é feita pelo InnCash, o</p>
                <p>seu ERP não precisa se preocupar com configuração</p>
                <p>de layouts e integrações bancárias. Toda a</p>
                <p>formatação da impressão dos boletos e toda a</p>
                <p>homologação bancária é feita pelo nosso time de</p>
                <p>implantação/suporte. São mais de 200 instituições</p>
                <p>financeiras para você trabalhar!</p>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center text-sm mt-14 md:mt-0">
              <img
                src={fileHandling}
                alt="Ícone azul com um cifrão dentro de uma engrenagem envolte de flechas"
                className="w-20 md:w-24 xl:w-24 xl:mt-4"
              />
              <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[17px] xl:text-xl mb-5">
                  Sem manuseio de arquivos
                </h2>
                <p>O InnCash garante uma integração bancária rápida</p>
                <p>e segura às instituições bancárias de sua</p>
                <p>preferência, seja através de APIs ou arquivos de</p>
                <p>Cnab. Independente da forma disponibilizada pelo</p>
                <p>seu banco, fizemos a integração sem que o usuário</p>
                <p>precise baixar arquivos e salva-los em pastas.</p>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 text-sm">
              <img
                src={banksReturns}
                alt="Ícone azul com um cifrão dentro de uma engrenagem envolte de flechas"
                className="w-16 xl:w-16 xl:mt-4"
              />
              <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[17px] xl:text-xl mb-5">
                  Retornos bancários automáticos
                </h2>
                <p>Assim que os bancos disponibilizarem os retornos</p>
                <p>de cobrança, o InnCash já os recebe e processa</p>
                <p>de forma automática. Sem ação manual.</p>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 text-sm">
              <img src={rejectShipments} alt="Ícone azul de banco" className="w-16 md:w-16 xl:mt-4" />
              <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[17px] xl:text-xl mb-5">
                  Rejeições e manutenções das remessas
                </h2>
                <p>O InnCash disponibiliza as ocorrências devolvidas</p>
                <p>pelo banco e, caso ocorra alguma rejeição, o título é</p>
                <p>automaticamente retirado do borderô, liberando-o</p>
                <p>para a devida correção e reenvio.</p>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 text-sm">
              <img src={erpTitles} alt="Ícone azul de banco" className="w-14 xl:w-15 xl:mt-4" />
              <div className="flex flex-col items-center  xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                  Baixa (liquidação) dos títulos no ERP
                </h2>
                <p>A leitura e o processamento dos retornos</p>
                <p>bancários são feitos pelo InnCash que, ao receber</p>
                <p>a confirmação de recebimento do banco, realiza</p>
                <p>as baixas dos títulos no ERP.</p>
              </div>
            </div>

            <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 text-sm">
              <img src={ticketSent} alt="Ícone azul de banco" className="w-16 xl:w-18 xl:mt-4" />
              <div className="flex flex-col items-center max-[380px]:text-[13px] xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 w-full">
                <h2 className="text-blue-inncash font-bold text-lg xl:text-xl">Boleto enviado ao cliente e 2ª via</h2>
                <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">disponível para o time</h2>
                <p>Com o InnCash, é possível fazer o envio automático dos</p>
                <p>boletos aos clientes. Além disso, o próprio financeiro</p>
                <p>também pode gerar 2ª via através do sistema, sem</p>
                <p>precisar acessar o site bancário para isso.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center">
        <div className="bg-gray-100-inncash py-10 xl:px-20 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center mx-5">
          <h1 className="text-blue-inncash font-bold text-xl lg:text-2xl xl:text-3xl mb-8 lg:mb-0 lg:w-[290px] 2xl:mr-32 xl:mr-28">
            Envio de instruções bancárias
          </h1>
          <p className="lg:w-[650px] lg:text-sm xl:text-normal">
            Baixa de título, alterações de vencimento, inclusão de acréscimo ou desconto podem ser enviadas ao sistema
            bancário de forma simples e rápida através do InnCash. O sistema avisa o usuário que houveram alterações em
            títulos com portador para que, após a confirmação, ocorra o envio automático das instruções ao sistema
            bancário.
          </p>
        </div>
      </section>

      <section className="mb-10 xl:mb-16">
        <div className="mx-3 xl:mx-40">
          <img src={printScreenBilling} alt="Print da tela de pagamentos dentro do Inncash" className="rounded-md" />
        </div>
      </section>

      <section>
        <div className="bg-blue-inncash text-white py-3 md:py-8 xl:py-8 md:pl-4 xl:pl-28 md:flex justify-between">
          <div className="mb-4 px-3 md:w-[450px] xl:w-[750px] md:flex flex-col justify-center">
            <div className={screenSize >= 768 ? "md:text-xl xl:text-5xl" : "hidden"}>
              <h1>
                Tenha <b>agilidade</b> em suas
              </h1>
              <h1>
                operações com o <b>PIX</b>
              </h1>
            </div>
            <div
              className={screenSize <= 768 ? "text-xl flex flex-col items-center justify-center mt-6 mb-4" : "hidden"}
            >
              <h1>
                Tenha <b>agilidade</b> em suas
              </h1>
              <h1>
                operações com o <b>PIX</b>
              </h1>
            </div>

            <div className="border-t border-white my-6">
              <span className="mt-5 flex justify-between items-center">
                <h2 className="font-bold text-md xl:text-xl">O queridinho do momento</h2>
                {sectionPix01IsOpen === true ? (
                  <MdMinimize
                    size={26}
                    onClick={openSectionPix01}
                    className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                  />
                ) : (
                  <MdAdd
                    size={26}
                    onClick={openSectionPix01}
                    className="hover:scale-110 transition duration-150 cursor-pointer"
                  />
                )}
              </span>
              <p className={sectionPix01IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                O Pix se tornou a forma de pagamento mais utilizada pelos brasileiros. Em 2022, foram mais de 24 bilhões
                de transações, o que equivale a uma média de 66 milhões de operações diárias. As transações do Pix
                superam as de cartão de débito, boleto, TED, DOC e cheques juntas, que totalizaram 20,9 bilhões.
              </p>
              <p className={sectionPix01IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                Ao oferecer essa opção de cobrança, a empresa amplia seu alcance e conveniência para os clientes,
                permitindo que eles paguem de forma rápida e prática, a qualquer momento e em qualquer lugar.
              </p>
            </div>

            <div className="border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">Recebimento ágil e dinheiro na conta</h2>
                  {sectionPix02IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionPix02}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionPix02}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionPix02IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  O Pix permite que as transações sejam realizadas instantaneamente, 24 horas por dia, 7 dias por
                  semana. Com isso, as cobranças podem ser liquidadas e o dinheiro ser disponibilizado na conta bancária
                  do credor em questão de segundos.
                </p>
                <p className={sectionPix02IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Isso proporciona maior agilidade e comodidade tanto para a empresa quanto para o cliente, pois é
                  possível automatizar, por exemplo, o processo de liberação de crédito de pedidos de forma automática e
                  praticamente online.
                </p>
              </div>
            </div>

            <div className="my-6 border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">A integração é por nossa conta</h2>
                  {sectionPix03IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionPix03}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionPix03}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionPix03IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Como é o InnCash quem cuida da integração com o(s) banco(s) de sua preferência e também monta o QR
                  Code dinâmico do PIX, o seu ERP não precisa se preocupar com configuração de layouts e integrações
                  bancárias. Todo o processo de homologação bancária é feita pelo nosso time de implantação/suporte.
                </p>
              </div>
            </div>

            <div className="border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">Você ainda pode reduzir seus custos financeiros</h2>
                  {sectionPix04IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionPix04}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionPix04}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionPix04IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Ao adotar o Pix como forma de cobrança, normalmente a empresa consegue uma economia financeira
                  significativa, pois muitas instituições financeiras estão com tarifas diferenciadas para cobrança via
                  Pix. É hora de aproveitar!
                </p>
              </div>
            </div>
          </div>

          <div className={screenSize <= 768 ? "mt-12 mb-4 flex justify-center" : "mt-12 mb-4"}>
            <img
              src={screenSize <= 768 ? notebookPixMobile : notebookPixDesktop}
              alt="Notebook aberto com Inncash na tela"
              className="w-[250px] md:w-[330px] xl:w-[600px]"
            />
          </div>
        </div>
      </section>

      <section className="mt-8 md:mt-10">
        <div className="flex flex-col items-center text-2xl xl:text-3xl justify-center mb-10">
          <h1>
            Cartão de <b>Crédito</b> ou <b>Débito</b>
          </h1>
        </div>

        <div className="mx-3 md:flex md:flex-col xl:flex-row md: items-center xl:justify-center md:gap-2 xl:gap-6">
          <div className="p-4 md:p-1 h-[340px] md:h-[350px] md:w-[350px] xl:w-[350px] xl:h-[370px] bg-gray-100-inncash rounded-3xl mb-4 flex flex-col items-center justify-center">
            <img src={weTakeCare} alt="Ícone branco de um prédio" className="mb-5 w-20 md:w-12 xl:w-24" />
            <h2 className="font-bold text-xl md:text-lg mb-5 text-blue-inncash">Nós cuidamos de tudo!</h2>
            <p className="md:text-sm">O InnCash é quem faz a integração</p>
            <p className="md:text-sm">com a adquirente de sua preferência.</p>
            <p className="md:text-sm">E também pode disponibilizar uma</p>
            <p className="md:text-sm">tela para captura dos dados do</p>
            <p className="md:text-sm">cartão, a qual pode ser integrada ao</p>
            <p className="md:text-sm">seu e-commerce ou portal do cliente.</p>
          </div>
          <div className="p-4 md:p-1 h-[340px] md:h-[350px] md:w-[350px] xl:w-[350px] xl:h-[370px] bg-gray-300-inncash rounded-3xl mb-4 flex flex-col items-center justify-center">
            <img src={weSendData} alt="Ícone branco de um banco" className="mb-5 w-14 md:w-12 xl:w-14" />
            <h2 className="font-bold text-xl max-[380px]:text-[17px] md:text-lg text-blue-inncash">
              Enviamos dos dados para adquirente
            </h2>
            <h2 className="font-bold text-xl max-[380px]:text-[17px] md:text-lg mb-5 text-blue-inncash">
              e confirmamos a operação para você
            </h2>
            <p className="md:text-sm max-[380px]:text-[14px]">O sistema envia as informações do cartão e</p>
            <p className="md:text-sm max-[380px]:text-[14px]">da cobrança para a operadora de cartão, que,</p>
            <p className="md:text-sm max-[380px]:text-[14px]">por sua vez, verifica a validade da transação e</p>
            <p className="md:text-sm max-[380px]:text-[14px]">retorna uma resposta de autorização ou</p>
            <p className="md:text-sm max-[380px]:text-[14px]">recusa para o sistema da empresa.</p>
          </div>
          <div className="p-4 md:p-1 h-[340px] md:h-[350px] md:w-[350px] xl:w-[350px] xl:h-[370px] bg-gray-100-inncash rounded-3xl flex flex-col items-center justify-center">
            <img
              src={reconciledTransfer}
              alt="Ícone branco de um selo com símbolo de check no centro"
              className="mb-5 w-20 md:w-12 xl:w-20"
            />
            <h2 className="font-bold text-xl md:text-lg mb-5 text-blue-inncash">Repasse conciliado!</h2>
            <p className="md:text-sm">Quando ocorrer a transferência dos</p>
            <p className="md:text-sm">valores da adquirente para a conta da</p>
            <p className="md:text-sm">empresa, o que pode variar de</p>
            <p className="md:text-sm">alguns dias a um mês, o InnCash efetua</p>
            <p className="md:text-sm">a baixa do título a receber de forma</p>
            <p className="md:text-sm">automática no seu ERP.</p>
          </div>
        </div>
        {screenSize <= 768 ? (
          <div className="flex flex-col items-center my-8 text-lg">
            <h1>
              Amplie suas <b>opções de cobrança</b>
            </h1>
            <h1>
              com o <span className="text-blue-inncash font-bold">InnCash</span>.
            </h1>
            <h1 className="mt-3">Com ele, você pode escolher o banco</h1>
            <h1> e o meio de pagamento mais apropriado</h1>
            <h1> ao seu negócio.</h1>
          </div>
        ) : (
          <div className="flex flex-col items-center my-10 md:text-2xl xl:text-3xl">
            <h1>
              Amplie suas <b>opções de cobrança</b> com o <span className="text-blue-inncash font-bold">InnCash</span>.
            </h1>
            <h1>Com ele, você pode escolher o banco e o meio de </h1>
            <h1>pagamento mais apropriado ao seu negócio.</h1>
          </div>
        )}
      </section>

      <section>
        <div className="bg-gray-100-inncash py-3 md:py-8 xl:py-8 md:pl-4 xl:pl-28 md:flex justify-between">
          <div className="mb-4 px-3 md:w-[450px] xl:w-[750px] md:flex flex-col justify-center">
            <div className={screenSize >= 768 ? "md:text-xl xl:text-5xl" : "hidden"}>
              <h1 className="mb-5">
                Módulo de <b>Cobrança</b>
              </h1>
              <span className="text-lg md:text-sm">
                <p>
                  Otimize a sua gestão financeira com nosso <b>módulo de</b>{" "}
                </p>
                <p>
                  <b>cobrança</b>, transformando dívidas em resultados positivos.
                </p>
              </span>
            </div>
            <div
              className={screenSize <= 768 ? "text-xl flex flex-col items-center justify-center mt-6 mb-4" : "hidden"}
            >
              <h1 className="mb-5">
                Módulo de <b>Cobrança</b>
              </h1>
              <span className="text-sm">
                <p>
                  Otimize a sua gestão financeira com nosso <b>módulo de</b>{" "}
                </p>
                <p>
                  <b>cobrança</b>, transformando dívidas em resultados positivos.
                </p>
              </span>
            </div>

            <div className="border-t border-black my-6">
              <span className="mt-5 flex justify-between items-center">
                <h2 className="text-blue-inncash font-bold text-md xl:text-xl">Agenda</h2>
                {sectionBilling01IsOpen === true ? (
                  <MdMinimize
                    size={26}
                    onClick={openSectionBilling01}
                    className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                  />
                ) : (
                  <MdAdd
                    size={26}
                    onClick={openSectionBilling01}
                    className="hover:scale-110 transition duration-150 cursor-pointer"
                  />
                )}
              </span>
              <p className={sectionBilling01IsOpen === true ? "mt-4 xl:w-[550px]" : "hidden"}>
                A agenda simplifica o planejamento e acompanhamento das atividades, garantindo que cada compromisso seja
                gerenciado de forma eficiente, otimizando o processo de cobrança.
              </p>
            </div>

            <div className="border-t border-black">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="text-blue-inncash font-bold text-md xl:text-xl">
                    Cadastro de operador e política de negociação
                  </h2>
                  {sectionBilling02IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionBilling02}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionBilling02}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionBilling02IsOpen === true ? "mt-4 xl:w-[550px]" : "hidden"}>
                  Cadastre todos os operadores que utilizaram a plataforma, com a possibilidade de criar uma regra de
                  substituição caso você entre de férias. Conseguimos também criar as politicas de negociação
                  específicas para cada operador, como diretrizes sobre prazos, descontos e parcelas. Com isso,
                  garantimos consistência e eficácia nas abordagens aos devedores.
                </p>
              </div>
            </div>

            <div className="my-6 border-t border-black">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="text-blue-inncash font-bold text-md xl:text-xl">Listagem de pendência </h2>
                  {sectionBilling03IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionBilling03}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionBilling03}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionBilling03IsOpen === true ? "mt-4 xl:w-[550px]" : "hidden"}>
                  A listagem mostra de forma organizada todas as pendências a serem tratadas. Ela fornece informações
                  cruciais, como dados do devedor e valores em aberto, permitindo que o operador consiga se organizar e
                  tratar rapidamente uma negociação.
                </p>
              </div>
            </div>

            <div className="border-t border-black">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="text-blue-inncash font-bold text-md xl:text-xl">Integração com o seu ERP</h2>
                  {sectionBilling04IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionBilling04}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionBilling04}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionBilling04IsOpen === true ? "mt-4 xl:w-[540px]" : "hidden"}>
                  Todas as novas negociações feitas na ferramenta seram integradras com o seu ERP, deixando a ficha
                  financeira do seu cliente totalmente atualizada.
                </p>
              </div>
            </div>

            <div className="my-6 border-t border-black">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="text-blue-inncash font-bold text-md xl:text-xl">Registro do boleto</h2>
                  {sectionBilling05IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionBilling05}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionBilling05}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionBilling05IsOpen === true ? "mt-4 xl:w-[560px]" : "hidden"}>
                  Após a conclusão de cada nova negociação, os boletos são automaticamente registrados e enviados por
                  e-mail para o seu cliente.
                </p>
              </div>
            </div>

            <div className="border-t border-black">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="text-blue-inncash font-bold text-md xl:text-xl">
                    Lembrete de vencimento dos boletos por e-mail
                  </h2>
                  {sectionBilling06IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSectionBilling06}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSectionBilling06}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={sectionBilling06IsOpen === true ? "mt-4 xl:w-[555px]" : "hidden"}>
                  Com sua funcionalidade de disparo de e-mails para títulos em aberto, você pode automatizar o processo
                  de cobrança, enviando lembretes X dias antes do vencimento e/ou após X dias do vencimento (caso não
                  tenha sido pago). Isso economiza tempo e ajuda a garantir que você receba seus pagamentos de forma
                  mais eficiente, mantendo seus clientes atualizados e minimizando a inadimplência.
                </p>
              </div>
            </div>
          </div>

          <div className={screenSize <= 768 ? "mt-12 mb-4 flex justify-center" : "mt-12 mb-4"}>
            <img
              src={screenSize <= 768 ? notebookBillingMobile : notebookBillingDesktop}
              alt="Notebook aberto com Inncash na tela"
              className="w-[250px] md:w-[330px] xl:w-[600px]"
            />
          </div>
        </div>
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>

      <Modal
        isOpen={modalIsOpenVideoBillingSolutions}
        onRequestClose={closeModalVideoBillingSolutions}
        style={modalStyle}
      >
        <span className="md:flex md:justify-end">
          <button onClick={closeModalVideoBillingSolutions}>
            <MdClose size={23} className="transition duration-150 hover:text-blue-inncash hover:scale-110" />
          </button>
        </span>
        <span className="flex justify-center">
          {screenSize <= 768 ? (
            <iframe
              width="370"
              height="350"
              src="https://www.youtube.com/embed/RnvO9AGNjsA"
              title="Soluções em Cobrança com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          ) : (
            <iframe
              width={screenSize <= 1100 ? "790" : "900"}
              height="550"
              src="https://www.youtube.com/embed/RnvO9AGNjsA"
              title="Soluções em Cobrança com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          )}
        </span>
      </Modal>
    </>
  );
}
