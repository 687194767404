import { IoCloseOutline } from "react-icons/io5";

export default function ListCompanies({ companies, handleDeleteCompany }) {
  function clickListCompanies() {
    return;
  }

  return (
    <>
      {companies.map((company, index) => {
        return (
          <div className="mt-5 flex flex-col md:flex-row items-center" key={company.corporateName}>
            <div className="flex flex-col md:flex-row w-full md:w-auto">
              <span className="text-sm font-bold bg-gray-70-inncash rounded-full px-6 py-[11px] md:w-[198px] w-full">
                {company.corporateName}
              </span>
              <span className="text-sm font-bold bg-gray-70-inncash rounded-full px-6 py-[11px] md:w-[198px] w-full md:ml-3 mt-4 md:mt-0">
                {company.cnpj}
              </span>
            </div>
            <div className="flex items-center mt-4 md:mt-0">
              <h3 className="ml-4 font-bold">Possui filiais?*</h3>
              <input
                type="radio"
                id=""
                name={`branchExist-${company.cnpj}`}
                className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
                onChange={clickListCompanies}
                checked={company.hasBranch}
              />
              <label htmlFor="" className="ml-1 font-bold">
                SIM
              </label>
              <input
                type="radio"
                id=""
                name={`branchExist-${company.cnpj}`}
                className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
                onChange={clickListCompanies}
                checked={!company.hasBranch}
              />
              <label htmlFor="" className="ml-1 font-bold">
                NÃO
              </label>
              <span
                className="text-sm font-bold bg-gray-70-inncash rounded-full lg:px-6 px-3 py-2 w-[70px] lg:w-[130px] ml-3"
                style={{ textAlign: "center" }}
              >
                {company.howManyBranches}
              </span>
            </div>

            <button onClick={() => handleDeleteCompany(index)}>
              <IoCloseOutline
                size={28}
                className="hover:text-red-500 text-gray-350-inncash transition duration-150 cursor-pointer ml-3"
              />
            </button>
          </div>
        );
      })}
    </>
  );
}
