import { useState } from "react";

import Modal from "react-modal";
import { MdClose, MdMinimize, MdAdd } from "react-icons/md";

import ContactForms from "../../components/ContactForms/ContactForms";

import videoPaymentSolutions from "../../assets/thumbs/video-payment-solutions.webp";
import playButton from "../../assets/icons/play.svg";
import remittancePayment from "../../assets/icons/remittance-payment.svg";
import fileHandling from "../../assets/icons/file-handling.svg";
import operationalAgility from "../../assets/icons/operational-agility.svg";
import multiBanksBlue from "../../assets/icons/multi-banks-blue.svg";
import printScreenPayment from "../../assets/img/print-screen-payment.webp";
import notebookPaymentDesktop from "../../assets/img/notebook-payment-desktop.webp";
import notebookPaymentMobile from "../../assets/img/notebook-payment-mobile.webp";
import multiCompany from "../../assets/icons/multi-company.svg";
import multiBanksWhite from "../../assets/icons/multi-banks-white.svg";
import multiApprovers from "../../assets/icons/multi-approvers.svg";

export default function Payment({ modalStyle, screenSize }) {
  const [modalIsOpenVideoPaymentSolutions, setModalIsOpenVideoPaymentSolutions] = useState(false);
  const [section01IsOpen, setSection01IsOpen] = useState(false);
  const [section02IsOpen, setSection02IsOpen] = useState(false);
  const [section03IsOpen, setSection03IsOpen] = useState(false);
  const [section04IsOpen, setSection04IsOpen] = useState(false);

  function openSection01() {
    setSection01IsOpen(!section01IsOpen);
  }

  function openSection02() {
    setSection02IsOpen(!section02IsOpen);
  }

  function openSection03() {
    setSection03IsOpen(!section03IsOpen);
  }

  function openSection04() {
    setSection04IsOpen(!section04IsOpen);
  }

  function openModalVideoPaymentSolutions() {
    setModalIsOpenVideoPaymentSolutions(true);
  }

  function closeModalVideoPaymentSolutions() {
    setModalIsOpenVideoPaymentSolutions(false);
  }

  return (
    <>
      <section className="mt-[81px] md:mt-20">
        <div className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row md:justify-between md:py-20 py-10 items-center min-[1700px]:px-36 xl:px-20 lg:px-12 md:px-4">
          <div className="flex flex-col md:items-start items-center text-white">
            <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">Soluções em</h1>
            <h1 className="font-bold text-4xl lg:text-6xl md:mb-7 mb-4 min-[1700px]:text-7xl">Pagamentos</h1>
            <p className="text-sm xl:text-lg min-[1700px]:text-xl">O InnCash oferece soluções para simplificar e</p>
            <p className="text-sm xl:text-lg min-[1700px]:text-xl">automatizar o fluxo de pagamentos de</p>
            <p className="text-sm xl:text-lg mb-2 min-[1700px]:text-xl">grandes empresas.</p>
            <p className="text-sm xl:text-lg min-[1700px]:text-xl">Otimize o tempo de seu time com uma</p>
            <p className="text-sm xl:text-lg md:mb-7 mb-6 min-[1700px]:text-xl">
              plataforma multibancos e multiempresas.
            </p>
            <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
              <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
            </button>
          </div>
          <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
            <img
              src={videoPaymentSolutions}
              alt="Thumbnail vídeo do youtube soluções de pagamentos Inncash"
              className="rounded-3xl md:w-[450px] lg:w-[550px] xl:w-[550px] w-[340px]"
            />
            <img
              src={playButton}
              onClick={openModalVideoPaymentSolutions}
              alt="Botão de play branco redondo"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
            />
          </span>
        </div>
      </section>

      <section className="mt-5 md:mt-12">
        {screenSize <= 768 ? (
          <div className="font-bold text-lg flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Simplifique o seu processo</h1>
            <h1>de pagamento</h1>
          </div>
        ) : (
          <div className="md:flex justify-center mb-8">
            <h1 className="md:text-xl xl:text-2xl font-bold">Simplifique o seu processo de pagamento</h1>
          </div>
        )}
        <div className="text-lg md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Confira as principais funcionalidades e</h1>
          <h1>
            benefícios do <b>Módulo de Pagamentos</b>
          </h1>
        </div>

        <div className="md:grid grid-cols-2 xl:mx-24">
          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img src={remittancePayment} alt="Ícone azul de cifrão" className="w-20 xl:w-242 xl:mt-4" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Geração de remessa de pagamento</h2>
              <p>A geração da remessa é feita pelo InnCash e, com</p>
              <p>isso, o seu ERP não precisa se preocupar com</p>
              <p>configuração de layouts e integrações com</p>
              <p>bancos. Toda a homologação bancária é feita</p>
              <p>pelo nosso time de implantação e suporte.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img src={fileHandling} alt="ïcone azul de cifrão dentro de uma núvem" className="w-20 xl:w-22 xl:mt-4" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Sem manuseio de arquivos</h2>
              <p>O InnCash garante pagamentos rápidos e seguros</p>
              <p>às instituições bancárias de sua preferência,</p>
              <p>eliminando a necessidade de baixar arquivos e</p>
              <p>salvá-los em pastas.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img
              src={operationalAgility}
              alt="Ícone azul com um cifrão dentro de uma engrenagem envolte de flechas"
              className="w-20 xl:w-22 xl:mt-4"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                Flexibilidade e agilidade na operação
              </h2>
              <p>Possibilidade de edição dos dados de pagamento </p>
              <p>(código de barras/linha digitável) ou dos dados</p>
              <p>bancários do fornecedor na própria tela de envio</p>
              <p>de remessa bancária, facilitando a operação de</p>
              <p>pagamento.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img src={multiBanksBlue} alt="Ícone azul de banco" className="w-20 xl:w-24 xl:mt-4" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                Todos os bancos, todas as empresas
              </h2>
              <p>O InnCash centraliza os pagamentos de todas as</p>
              <p>empresas do grupo, os quais podem ser enviados</p>
              <p>para múltiplos bancos. Tudo de uma única tela.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center mx-4">
        <div className="bg-gray-100-inncash py-10 xl:px-20 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center">
          <h1 className="text-blue-inncash font-bold text-xl lg:text-2xl xl:text-3xl lg:w-[220px] xl:w-[280px] 2xl:mr-60 xl:mr-30 mb-4 lg:mb-0">
            E todas as formas de pagamento
          </h1>
          <p className="md:w-[650px] lg:text-sm xl:text-normal">
            Com o InnCash, é possível realizar os pagamentos de boletos, transferências entre contas, TED, PIX,
            concessionárias (Contas de consumo) e tributos com ou sem código de barras. Tudo em uma única remessa.
          </p>
        </div>
      </section>

      <section className="mb-10 xl:mb-16">
        <div className="mx-3 md:mx-6 xl:mx-40">
          <img src={printScreenPayment} alt="Print da tela de pagamentos dentro do Inncash" className="rounded-md" />
        </div>
      </section>

      <section>
        <div className="bg-blue-inncash text-white py-3 md:py-8 xl:py-8 md:pl-4 xl:pl-28 md:flex justify-between">
          <div className="mb-4 px-3 md:w-[450px] xl:w-[750px] md:flex flex-col justify-center">
            <div className={screenSize >= 768 ? "md:text-xl xl:text-5xl" : "hidden"}>
              <h1>
                <b>Aposente</b> o leitor de
              </h1>
              <h1>código de barras</h1>
            </div>
            <div className={screenSize <= 768 ? "text-xl flex items-center justify-center mt-6 mb-4" : "hidden"}>
              <h1>
                <b>Aposente</b> o leitor de código de barras
              </h1>
            </div>

            <div className="border-t border-white my-6">
              <span className="mt-5 flex justify-between items-center">
                <h2 className="font-bold text-md xl:text-xl">Varredura de sacado automática</h2>
                {section01IsOpen === true ? (
                  <MdMinimize
                    size={26}
                    onClick={openSection01}
                    className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                  />
                ) : (
                  <MdAdd
                    size={26}
                    onClick={openSection01}
                    className="hover:scale-110 transition duration-150 cursor-pointer"
                  />
                )}
              </span>
              <p className={section01IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                O InnCash recebe diária e automaticamente todos os boletos que foram registrados contra o(s) seu(s)
                CNPJ(s).
              </p>
            </div>

            <div className="border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">Conciliação com os títulos do ERP</h2>
                  {section02IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSection02}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSection02}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={section02IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Através de uma comparação entre os boletos recebidos do sistema bancário e os títulos lançados no seu
                  ERP, o InnCash faz o preenchimento automático do código de barras.
                </p>
              </div>
            </div>

            <div className="my-6 border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">Correção de dados lançados incorretamente</h2>
                  {section03IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSection03}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSection03}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={section03IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Tendo como base critérios de tolerância de divergências de valor e vencimento, o InnCash pode realizar
                  correções nos títulos do ERP, para que fiquem equivalentes aos boletos registrados no sistema
                  bancário.
                </p>
              </div>
            </div>

            <div className="border-t border-white">
              <div>
                <span className="mt-5 flex justify-between items-center">
                  <h2 className="font-bold text-md xl:text-xl">Geração de faturas</h2>
                  {section04IsOpen === true ? (
                    <MdMinimize
                      size={26}
                      onClick={openSection04}
                      className="-mt-4 hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  ) : (
                    <MdAdd
                      size={26}
                      onClick={openSection04}
                      className="hover:scale-110 transition duration-150 cursor-pointer"
                    />
                  )}
                </span>
                <p className={section04IsOpen === true ? "mt-4 xl:w-[500px]" : "hidden"}>
                  Muito útil, por exemplo, em casos de pagamento de transportadoras, em que há um boleto para vários
                  títulos a pagar (conhecimentos de transporte). Com o InnCash, você pode vincular dois ou mais títulos
                  do ERP com um único boleto do DDA de forma muito simples.
                </p>
              </div>
            </div>
          </div>

          <div className={screenSize <= 768 ? "mt-12 mb-4 flex justify-center" : "mt-12 mb-4"}>
            <img
              src={screenSize <= 768 ? notebookPaymentMobile : notebookPaymentDesktop}
              alt="Notebook aberto com Inncash na tela"
              className="w-[250px] md:w-[330px] xl:w-[600px]"
            />
          </div>
        </div>
      </section>

      <section className="mt-8 xl:mt-16 xl:flex flex-col items-center">
        <div className="text-xl md:text-2xl xl:text-4xl flex items-center justify-center mb-6 xl:mb-12">
          <h1>
            Deixe os retornos <b>conosco</b>
          </h1>
        </div>
        <div className="mx-3 md:mx-4 md:grid grid-cols-2 md:gap-3 xl:gap-8 place-items-center">
          <div className="bg-gray-100-inncash rounded-3xl mb-4 md:mb-0 p-2 md:px-4 md:py-6 xl:p-14 xl:w-[530px] xl:h-[190px] md:h-[180px] flex flex-col items-center xl:justify-center md:items-start">
            <h2 className="font-bold text-blue-inncash my-3 xl:mb-4">Retornos bancários automáticos</h2>
            <p className="md:text-sm xl:w-[450px]">
              Assim que os bancos disponibilizarem os retornos de pagamentos, o InnCash já os recebe e processa de forma
              automática. Sem ação manual.
            </p>
          </div>

          <div className="bg-gray-300-inncash rounded-3xl mb-4 md:mb-0 p-2 md:px-4 md:py-6 xl:p-14 xl:w-[530px] xl:h-[190px] md:h-[180px] flex flex-col items-center xl:justify-center md:items-start">
            <h2 className="font-bold text-blue-inncash my-3 xl:mb-4">Rejeições e manutenções das remessas</h2>
            <p className="md:text-sm xl:w-[450px]">
              O InnCash disponibiliza as ocorrências devolvidas pelo banco e, caso ocorra alguma rejeição, o título a
              pagar é automaticamente retirado do borderô, liberando-o para a devida correção e reenvio.
            </p>
          </div>

          <div className="bg-gray-300-inncash rounded-3xl mb-4 md:mb-0 p-2 md:px-4 md:py-6 xl:p-14 xl:w-[530px] xl:h-[190px] md:h-[180px] flex flex-col items-center xl:justify-center md:items-start">
            <h2 className="font-bold text-blue-inncash my-3 xl:mb-4">Baixa (liquidação) dos títulos no ERP</h2>
            <p className="md:text-sm xl:w-[450px]">
              A leitura e o processamento dos retornos bancários são feitos pelo InnCash que, ao receber a confirmação
              de pagamento do banco, realiza as baixas dos títulos no ERP.
            </p>
          </div>

          <div className="bg-gray-100-inncash rounded-3xl mb-4 md:mb-0 p-2 md:px-4 md:py-6 xl:p-14 xl:w-[530px] xl:h-[190px] md:h-[180px] flex flex-col items-center xl:justify-center md:items-start">
            {screenSize <= 768 ? (
              <div className="flex flex-col items-center">
                <h2 className="font-bold text-blue-inncash mt-3">Disponibilização de comprovantes</h2>
                <h2 className="font-bold text-blue-inncash mb-3 xl:mb-4">de pagamento</h2>
              </div>
            ) : (
              <div>
                <h2 className="font-bold text-blue-inncash my-3 xl:mb-4">
                  Disponibilização de comprovantes de pagamento
                </h2>
              </div>
            )}

            <p className="md:text-sm xl:w-[450px]">
              O InnCash disponibiliza todos os comprovantes dos títulos pagos via sistema. Inclusive, permite o disparo
              automático desses comprovantes por e-mail para o fornecedor.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-10 bg-payment bg-cover bg-no-repeat py-8 xl:flex flex-col items-end xl:pr-24 xl:py-16">
        <div className="text-white">
          <div className="flex flex-col items-center xl:items-start justify-center text-2xl xl:text-4xl mb-6 xl:mb-8">
            <h1>Uma plataforma para</h1>
            <h1>
              <b>todos os tipos de pagamentos</b>
            </h1>
          </div>
          <div className="mx-3 md:mx-20 xl:mx-0">
            <div className="bg-blue-900-inncash rounded-3xl p-3 md:py-8 md:px-14 mb-4 h-[200px] md:h-[170px] xl:w-[790px] flex flex-col items-center xl:items-start justify-center">
              <h2 className="font-bold mb-3 text-xl">Folha de pagamento</h2>
              <p>
                O InnCash faz o envio dos arquivos de folha de pagamento (salários, férias, etc) ao banco de sua
                preferência. Com isso, o RH não precisa ter acesso à conta da empresa para fazer o envio ou analisar o
                retorno.
              </p>
            </div>
            <div className="bg-blue-900-inncash rounded-3xl p-3 md:py-8 md:px-14 mb-4 h-[200px] md:h-[170px] xl:w-[790px] flex flex-col items-center xl:items-start justify-center">
              <h2 className="font-bold mb-3 text-xl">Devolução de clientes</h2>
              <p>
                Com o InnCash, é possível enviar os títulos de devolução (reembolso) de clientes como um outro pagamento
                qualquer.
              </p>
            </div>
            <div className="bg-blue-900-inncash rounded-3xl p-3 md:py-8 md:px-14 mb-4 h-[200px] md:h-[170px] xl:w-[790px] flex flex-col items-center xl:items-start justify-center">
              <h2 className="font-bold mb-3 text-xl">Adiantamentos a fornecedor</h2>
              <p>
                No InnCash, é possível realizar o pagamento de adiantamentos ao fornecedor, com lançamento automático
                dos mesmos no ERP.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-8 md:mt-10">
        {screenSize <= 768 ? (
          <div className="flex flex-col items-center text-2xl justify-center mb-6">
            <h1>Aprove todos os pagamentos</h1>
            <h1>
              <b>em uma única tela</b>
            </h1>
          </div>
        ) : (
          <div className="flex flex-col items-center text-2xl xl:text-3xl justify-center mb-10">
            <h1>
              Aprove todos os pagamentos <b>em uma única tela</b>
            </h1>
          </div>
        )}

        <div className="mx-3 text-white md:flex flex-col xl:flex-row items-center xl:justify-center md:gap-1 xl:gap-6">
          <div className="p-4 md:p-1 h-[300px] md:h-[250px] md:w-[350px] xl:w-[330px] xl:h-[280px] bg-blue-inncash rounded-3xl mb-4 xl:mb-0 flex flex-col items-center justify-center">
            <img src={multiCompany} alt="Ícone branco de um prédio" className="mb-5 w-18 md:w-12 xl:w-16" />
            <h2 className="font-bold text-xl md:text-lg mb-5">Multiempresas</h2>
            <p className="md:text-sm">Com o InnCash, você pode fazer a</p>
            <p className="md:text-sm">aprovação dos pagamentos de todas as</p>
            <p className="md:text-sm">empresas do grupo, em uma única tela.</p>
          </div>
          <div className="p-4 md:p-1 h-[300px] md:h-[250px] md:w-[350px] xl:w-[330px] xl:h-[280px] bg-purple-400-inncash rounded-3xl mb-4 xl:mb-0 flex flex-col items-center justify-center">
            <img src={multiBanksWhite} alt="Ícone branco de um banco" className="mb-5 w-24 md:w-12 xl:w-20" />
            <h2 className="font-bold text-xl md:text-lg mb-5">Multibancos</h2>
            <p className="md:text-sm">Entrar no internet banking de cada banco</p>
            <p className="md:text-sm">para autorizar os pagamentos? Não com o</p>
            <p className="md:text-sm">InnCash.</p>
          </div>
          <div className="p-4 md:p-1 h-[300px] md:h-[250px] md:w-[350px] xl:w-[330px] xl:h-[280px] bg-blue-inncash rounded-3xl flex flex-col items-center justify-center">
            <img
              src={multiApprovers}
              alt="Ícone branco de um selo com símbolo de check no centro"
              className="mb-5 w-24 md:w-12 xl:w-20"
            />
            <h2 className="font-bold text-xl md:text-lg mb-5">Multiaprovadores</h2>
            <p className="md:text-sm">O InnCash pode trabalhar com único ou</p>
            <p className="md:text-sm">multiaprovadores, de acordo com o</p>
            <p className="md:text-sm">compliance da empresa.</p>
          </div>
        </div>
        {screenSize <= 768 ? (
          <div className="flex flex-col items-center my-8 text-xl">
            <h1>
              Quer <b>simplificar</b> os processos de
            </h1>
            <h1>pagamentos da sua empresa?</h1>
          </div>
        ) : (
          <div className="flex flex-col items-center my-10 md:text-2xl xl:text-3xl">
            <h1>
              Quer <b>simplificar</b> os processos de pagamentos da sua empresa?
            </h1>
          </div>
        )}
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>

      <Modal
        isOpen={modalIsOpenVideoPaymentSolutions}
        onRequestClose={closeModalVideoPaymentSolutions}
        style={modalStyle}
      >
        <span className="md:flex md:justify-end">
          <button onClick={closeModalVideoPaymentSolutions}>
            <MdClose size={23} className="transition duration-150 hover:text-blue-inncash hover:scale-110" />
          </button>
        </span>
        <span className="flex justify-center">
          {screenSize <= 768 ? (
            <iframe
              width="370"
              height="350"
              src="https://www.youtube.com/embed/ALCNJfa0FT4"
              title="Soluções em Pagamentos com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          ) : (
            <iframe
              width={screenSize <= 1100 ? "790" : "900"}
              height="550"
              src="https://www.youtube.com/embed/ALCNJfa0FT4"
              title="Soluções em Pagamentos com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          )}
        </span>
      </Modal>
    </>
  );
}
