import { Link } from "react-router-dom";

import notebook from "../../../assets/img/notebook-bank-conciliation.webp";
import extractIcon from "../../../assets/icons/extract.svg";
import inconsistencyIcon from "../../../assets/icons/inconsistency.svg";
import lauchIcon from "../../../assets/icons/launch.svg";
import bankIcon from "../../../assets/icons/multi-banks-blue.svg";

export default function BankConciliation({ tab }) {
  return (
    <div
      className={
        tab !== "tab3"
          ? "hidden"
          : "flex md:flex-row flex-col xl:h-150 items-center md:justify-between md:items-start relative lg:mx-24 xl:mx-0 sm:mx-0"
      }
    >
      <div className="text-white md:mt-12 mt-4 xl:w-[590px] md:w-[340px] w-[300px] mb-10 flex flex-col items-center md:items-start 2xl:ml-12">
        <h2 className="font-bold xl:text-3xl text-lg mb-5 xl:w-[500px] md:w-[350px]">
          Conciliação bancária de forma automatizada e eficiente.
        </h2>
        <p className="md:text-[15px] xl:text-xl text-xs xl:w-[560px] md:w-[280px]">
          Automatize a conciliação bancária das contas de sua empresa e poupe
          tempo do seu time.
        </p>
        <img
          src={notebook}
          alt="Notebook aberto com Inncash na tela"
          className="xl:mt-10 mt-6 xl:ml-5 xl:w-[400px] md:w-[280px]"
        />
      </div>

      <div className="flex flex-col justify-center -mt-48 md:mt-0 md:-ml-3">
        <div className="mt-44 md:mt-10 flex flex-col justify-center items-center">
          <h2 className="md:text-xl text-lg mb-5 text-white">
            Com o <strong>InnCash</strong>, você tem:
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-2 max-[380px]:gap-1 xl:gap-5 md:gap-4">
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={extractIcon}
              alt="Ícone banco azul"
              className="md:mb-5 mb-2 w-[30px] xl:w-[45px] md:w-[37px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px] max-[380px]:text-[10px]">
              Extrato disponível antes
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px] max-[380px]:text-[10px]">
              de amanhecer
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={inconsistencyIcon}
              alt="Ícone azul de papel com retorno "
              className="md:mb-5 mb-2 w-[40px] md:w-[50px] xl:w-[60px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Apontamos automaticamente
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              qualquer inconsistência
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={lauchIcon}
              alt="Ícone lupa azul"
              className="md:mb-5 mb-2 w-[35px] md:w-[45px] xl:w-[50px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Lançamos as tarifas
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              para você
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={bankIcon}
              alt="Ícone de papel azul"
              className="md:mb-5 mb-2 w-[45px] md:w-[55px] xl:w-[70px]"
            />
            <p className="xl:text-[18px] max-[380px]:text-[11px] md:text-[14px] text-[11px]">
              Tudo para conciliar o seu caixa
            </p>
            <p className="xl:text-[18px] max-[380px]:text-[11px] md:text-[14px] text-[11px]">
              em uma plataforma multibancos
            </p>
          </span>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <Link to="/solucoes-para-conciliacao-bancaria">SAIBA MAIS</Link>
          </button>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <a href="#contact-section">SOLICITAR DEMONSTRAÇÃO</a>
          </button>
        </div>
      </div>
    </div>
  );
}
