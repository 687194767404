import collaboratorsInnova from "../assets/img/collaborators-innova.webp";
import Customers from "./Home/Customers";

export default function About({ customersList, screenSize }) {
  return (
    <>
      <img
        src={collaboratorsInnova}
        alt="Imagem com os colaboradores da Innova Connect"
        className="bg-cover w-full xl:mb-10 mb-6 md:mt-20 mt-20"
      />
      <div className="flex flex-col items-center">
        <div className="flex flex-col xl:flex-row items-center xl:justify-between mx-3 md:mx-4 mt-6 xl:mt-10 xl:w-full">
          <h1 className="font-bold text-2xl xl:text-4xl xl:ml-24 min-[1700px]:ml-48">Nossa História</h1>
          <div className="bg-gray-100-inncash h-[400px] max-[380px]:h-[425px] md:h-[280px] xl:h-[300px] xl:w-[1100px] w-full xl:mx-0 p-4 xl:p-8 xl:flex flex-col justify-center xl:text-lg rounded-3xl mt-4 md:mt-0  ">
            <div className="xl:mr-36">
              <p className="mb-4 md:mt-8 xl:mt-0">
                Os processos financeiros das empresas de grande porte são praticamente iguais há décadas:{" "}
                <b>burocráticos e nada estratégicos</b>, o que demanda muito tempo do time.
              </p>
              <p className="mb-4">
                Incorformados com essa realidade, em 2018 desenvolvemos o <b>InnCash</b>.
              </p>
              <p>
                O nome InnCash surgiu em menção à sua primeira funcionalidade, que era gestão de fluxo de caixa. Ao
                longo dos anos, <b>o sistema tornou-se mais amplo e integrado com as instituições bancárias</b>. Hoje, a
                ferramenta apresenta soluções completas para simplificar as operações financeiras de grandes empresas.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className={screenSize >= 768 ? "flex flex-col items-center mt-18 mx-2" : "hidden"}>
            <h1 className="font-bold text-xl xl:text-3xl mb-2 xl:mb-6">Quem somos</h1>
            <p className="text-xl">
              Atualmente, contamos com uma equipe especialista e focada em realizar atendimentos
            </p>
            <p className="mb-8 text-xl">individualizados e com excelência aos nossos clientes:</p>
          </div>

          <div className={screenSize <= 768 ? "flex flex-col items-center mt-8 mx-2" : "hidden"}>
            <h1 className="font-bold text-xl xl:text-2xl mb-2">Quem somos</h1>
            <p className="max-[380px]:text-sm">Atualmente, contamos com uma equipe especialista</p>
            <p className="max-[380px]:text-sm">e focada em realizar atendimentos individualizados</p>
            <p className="mb-8 max-[380px]:text-sm">e com excelência aos nossos clientes:</p>
          </div>

          <div className="flex flex-col items-center xl:grid grid-cols-3 xl:gap-8 md:gap-1 text-white text-lg">
            <div className="bg-blue-inncash h-[200px] w-[340px] md:w-[270px] xl:w-[380px] xl:text-xl md:text-sm rounded-3xl mb-4 flex flex-col items-center justify-center">
              <p>
                Oferecemos <b>atendimento pelo</b>
              </p>
              <p>
                <b>WhatsApp</b> com um gerente de
              </p>
              <p>contas dedicado, para aprimorar</p>
              <p>sua experiência.</p>
            </div>
            <div className="bg-blue-inncash h-[200px] w-[340px] md:w-[270px] xl:w-[380px] xl:text-xl md:text-sm rounded-3xl mb-4 flex flex-col items-center justify-center">
              <p>Realizamos um processo de</p>
              <p>
                <b>Onboarding exclusivo</b> para ajudá-lo em
              </p>
              <p>todas as configurações e garantir o</p>
              <p>sucesso do seu projeto desde o início.</p>
            </div>
            <div className="bg-blue-inncash h-[200px] w-[340px] md:w-[270px] xl:w-[380px] xl:text-xl md:text-sm rounded-3xl mb-4 flex flex-col items-center justify-center">
              <p>Nossa equipe de suporte ao cliente</p>
              <p>
                realiza os <b>atendimentos de forma</b>
              </p>
              <p>
                <b>humanizada</b> através de chats online.
              </p>
            </div>
          </div>
        </div>

        <section className="mt-10 md:mt-14 w-full">
          <div>
            <div className="md:mx-6 xl:mx-48 min-[1700px]:mx-64 flex flex-col">
              <div className=" font-bold md:text-2xl max-[380px]:text-[12px] text-[13px] flex flex-col items-center mb-5">
                <h1>Faça como estas grandes marcas e mais de 200 outras que já</h1>
                <h1 className="text-blue-inncash">
                  automatizam pagamentos e cobranças com o InnCash
                  <span className="text-black">.</span>
                </h1>
              </div>
              <div className="grid xl:grid-cols-7 grid-cols-2 place-items-center">
                {customersList.map((Customer) => {
                  return <Customers key={Customer.id} href={Customer.href} src={Customer.src} alt={Customer.alt} />;
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
