import React, { useContext } from "react";
import ButtonVolumetryForm from "../../../contexts/ButtonVolumetryForm";
import StepVolumetryForm from "../../../contexts/StepVolumetryForm";

import "../../../styles/stepper.css";

export default function Stepper({ moduleQuestionBuilder, validateForm, handleSubmit, locationPath, errors }) {
  const { currentStep, setCurrentStep } = useContext(StepVolumetryForm);
  const { lastStepForm, handleNextModuleQuestions, questionsModules } = useContext(ButtonVolumetryForm);

  let stepAmount = 3;

  if (questionsModules.length > 0) {
    stepAmount = questionsModules.length + 1;
  }

  async function handleAdvanceButton(event, action) {
    event.preventDefault();

    if (currentStep === 1) {
      const isValid = await validateForm(["email", "companies", "modules"]);
      if (!isValid) return;
    }

    if (!lastStepForm) {
      let loadingButton = document.getElementById("form-loader-advance");
      let textButton = document.getElementById("form-advance");
      loadingButton.classList.remove("hidden");
      loadingButton.classList.add("block");
      textButton.classList.remove("block");
      textButton.classList.add("hidden");

      await sleep(400).then(() => {
        loadingButton.classList.remove("block");
        loadingButton.classList.add("hidden");
        textButton.classList.remove("hidden");
        textButton.classList.add("block");
      });
    } else {
      let loadingButtonSubmit = document.getElementById("form-loader-submit");
      let textButtonSubmit = document.getElementById("form-submit");
      loadingButtonSubmit.classList.remove("hidden");
      loadingButtonSubmit.classList.add("block");
      textButtonSubmit.classList.remove("block");
      textButtonSubmit.classList.add("hidden");

      await sleep(400).then(() => {
        loadingButtonSubmit.classList.remove("block");
        loadingButtonSubmit.classList.add("hidden");
        textButtonSubmit.classList.remove("hidden");
        textButtonSubmit.classList.add("block");
      });
    }

    if (currentStep === 1 && action === "advance") {
      await moduleQuestionBuilder();
    }

    if (currentStep !== 1) {
      await handleNextModuleQuestions(action);
    }

    if (action === "advance") {
      setCurrentStep((prev) => prev + 1);
    } else {
      setCurrentStep((prev) => prev - 1);
    }
  }

  function sleep(milliseconds) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  return (
    <>
      <div className="flex justify-between my-8 md:mx-[230px] mx-10 border-b-2 border-gray-70-inncash">
        {Array.from({ length: stepAmount }, (_, index) => (
          <div
            key={index}
            className={`step-item
              ${currentStep === index + 1 ? "active" : ""} 
              ${index < currentStep ? "complete" : ""}
            `}
          >
            <div className="step" />
            <p className="text-gray-500" />
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {!lastStepForm ? (
          <>
            <div id="form-advance">
              <button
                className={
                  currentStep === 1
                    ? "hidden"
                    : "mr-3 text-lg font-bold rounded-3xl px-13 py-2 text-gray-400 bg-gray-70-inncash hover:bg-green-500-attention-hover hover:text-white transition duration-150"
                }
                type="submit"
                disabled={currentStep === 1 ? true : false}
                onClick={(event) => handleAdvanceButton(event, "return")}
              >
                Voltar
              </button>
              <button
                className="text-lg font-bold rounded-3xl px-12 py-2 text-white bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150"
                onClick={(event) => handleAdvanceButton(event, "advance")}
              >
                Avançar
              </button>
            </div>

            <div className="px-20 hidden" id="form-loader-advance">
              <div className="mx-auto h-10 w-10 border-4 border-l-gray-200 border-r-gray-200 border-b-gray-200 border-t-blue-inncash animate-spin ease-linear rounded-full"></div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col justify-center">
              <div id="form-submit">
                <button
                  className="mr-3 text-lg font-bold rounded-3xl px-12 py-2 text-gray-400 bg-gray-70-inncash hover:bg-green-500-attention-hover hover:text-white transition duration-150"
                  onClick={(event) => handleAdvanceButton(event, "return")}
                >
                  Voltar
                </button>
                <button
                  className="text-lg font-bold rounded-3xl px-12 py-2 text-white bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150"
                  onClick={handleSubmit}
                >
                  {locationPath.pathname.startsWith("/novo-formulario/interno") ? "Salvar" : "Enviar"}
                </button>
                <div className="flex items-center justify-center mt-4">
                  <span className="font-bold text-xs text-red-500 ml-2">
                    {Object.keys(errors).length > 0 && "Preencha todos os campos"}
                  </span>
                </div>
              </div>

              <div className="px-20 hidden" id="form-loader-submit">
                <div className="mx-auto h-10 w-10 border-4 border-l-gray-200 border-r-gray-200 border-b-gray-200 border-t-blue-inncash animate-spin ease-linear rounded-full"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
