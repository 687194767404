const paymentMethods = [
  {
    name: "Boleto",
    method: "bankSlip",
  },
  {
    name: "PIX puro/dinâmico",
    method: "pix",
  },
  {
    name: "Cartão de crédito/débito",
    method: "card",
  },
  {
    name: "Débito automático",
    method: "directDebit",
  },
];

export default paymentMethods;
