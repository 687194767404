import Client from "./Client";

export default class Http {
  constructor() {
    this.client = Client;
  }

  get(url = "", params = {}, config) {
    return this.client.get(url, { ...config, params });
  }

  post(url, data, config) {
    return this.client.post(url, data, config);
  }

  put(url, data, config) {
    return this.client.put(url, data, config);
  }

  patch(url, data, config) {
    return this.client.patch(url, data, config);
  }

  delete(url, config) {
    return this.client.delete(url, config);
  }
}
