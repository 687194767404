import { useState } from "react";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";

import ContactForms from "../../components/ContactForms/ContactForms";

import videoCashFlow from "../../assets/thumbs/video-cash-flow.webp";
import playButton from "../../assets/icons/play.svg";
import dataIcon from "../../assets/icons/data.svg";
import bankBalanceIcon from "../../assets/icons/bank-balance.svg";
import printScreenCashFlow from "../../assets/img/print-screen-cash-flow.webp";
import flexibilityImage from "../../assets/img/flexibility-cash-flow.webp";
import aprovalImage from "../../assets/img/approval-cash-flow.webp";

export default function CashFlow({ modalStyle, screenSize }) {
  const [modalIsOpenVideoCashFlow, setModalIsOpenVideoCashFlow] =
    useState(false);

  function openModalVideoCashFlow() {
    setModalIsOpenVideoCashFlow(true);
  }

  function closeModalVideoCashFlow() {
    setModalIsOpenVideoCashFlow(false);
  }

  return (
    <>
      <section className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between md:py-16 py-10 items-center min-[1700px]:px-36 xl:px-20 lg:px-12 md:px-2 md:mt-20 mt-[81px]">
        <div className="flex flex-col md:items-start items-center text-white md:ml-3 xl:ml-0">
          <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">
            Soluções para
          </h1>
          <h1 className="font-bold text-4xl lg:text-6xl md:mb-7 mb-4 min-[1700px]:text-7xl">
            Fluxo de Caixa
          </h1>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">
            Transforme a gestão de caixa da sua empresa em
          </p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">
            algo simples e automatizado. Tenha uma visão
          </p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">
            clara e precisa dos pagamentos e recebimentos,
          </p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">
            tomando decisões fundamentadas.{" "}
          </p>
          <p className="text-sm xl:text-lg mt-2 min-[1700px]:text-xl">
            Reduza a complexidade financeira e experimente
          </p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">
            agora mesmo a facilidade e a eficiência do
          </p>
          <p className="text-sm xl:text-lg mb-6 min-[1700px]:text-xl">
            InnCash.
          </p>
          <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
            <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
          </button>
        </div>
        <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
          <img
            src={videoCashFlow}
            alt="Thumbnail vídeo do youtube soluções de pagamentos Inncash"
            className="rounded-3xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
          />
          <img
            src={playButton}
            onClick={openModalVideoCashFlow}
            alt="Botão de play branco redondo"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
          />
        </span>
      </section>

      <section className="mt-5 md:mt-12 mx-3">
        {screenSize <= 768 ? (
          <div className="font-bold text-lg flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Fluxo de caixa automatizado</h1>
          </div>
        ) : (
          <div className="md:flex justify-center text-lg mb-8 mt-6 md:mt-0">
            <h1 className="md:text-xl xl:text-2xl font-bold">
              Fluxo de caixa automatizado
            </h1>
          </div>
        )}
        <div className="text-[17px] md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Todas as informações que você precisa de</h1>
          <h1>
            um jeito <b>prático, dinâmico e flexível</b>
          </h1>
        </div>

        <div className="md:grid grid-cols-2 xl:mx-24 xl:mt-16 max[380px]-mx-2">
          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 xl:mt-0">
            <img
              src={dataIcon}
              alt="Ícone azul de cifrão"
              className="w-20 xl:w-22 xl:mt-8"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                Dados atualizados em tempo real
              </h2>
              <p>O InnCash se conecta ao seu ERP para oferecer</p>
              <p>informações atualizadas e precisas sobre o fluxo</p>
              <p>de caixa da empresa, permitindo uma visão clara e</p>
              <p>imediata da situação financeira do seu negócio,</p>
              <p>independente do lugar que você estiver.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img
              src={bankBalanceIcon}
              alt="ïcone azul de cifrão dentro de uma núvem"
              className="w-18 md:w-16 xl:mt-5"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[16px] xl:text-xl mb-5">
                Saldo bancário atualizado automaticamente
              </h2>
              <p>No fluxo de caixa previsto do InnCash, os saldos das</p>
              <p>contas bancárias são atualizados automaticamente</p>
              <p>todos os dias pela manhã, de acordo com o saldo real</p>
              <p>do seu banco. Você não precisará mais acessar as</p>
              <p>contas para verificar a disponibilidade de recursos.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center">
        <div className="bg-gray-100-inncash py-10 xl:px-12 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center mx-4">
          <div className="flex flex-col items-center lg:items-start text-blue-inncash font-bold text-xl md:text-2xl xl:text-xl mb-4 lg:mb-0 2xl:mr-28 xl:mr-10 lg:mr-8">
            <h1 className="md:text-3xl text-xl lg:text-2xl">
              Personalização da visualização
            </h1>
            <h1 className="text-xl lg:text-xl">
              Ajustamos o formato conforme as
            </h1>
            <h1 className="text-xl lg:text-xl">
              características do seu negócio.
            </h1>
          </div>
          <p className="lg:w-[560px] lg:text-sm xl:text-normal">
            Os saldos bancários, as contas a pagar e as contas a receber da
            empresa são apresentadas de forma dinâmica, estruturadas de acordo
            com o seu negócio. Todos os dados são categorizados de forma
            adequada para facilitar a análise e o acompanhamento. A apresentação
            desses dados por meio do BI, agiliza sua gestão estratégica e
            performance de análise de dados.
          </p>
        </div>
      </section>

      <section className="xl:mb-16 mx-4 xl:mx-40">
        <div>
          <img
            src={printScreenCashFlow}
            alt="Print da tela de pagamentos dentro do Inncash"
            className="rounded-md"
          />
        </div>
      </section>

      <section className="md:mt-16 mt-10 mx-5">
        <div className="xl:flex xl:flex-row justify-center xl:gap-5 md:grid place-items-center xl:mx-14 md:mx-4 flex flex-col items-center">
          <div className="mb-8 xl:mb-0 md:w-[550px]">
            <img
              src={flexibilityImage}
              alt="Mulher de óculos mexendo em notebook"
              className="rounded-t-3xl"
            />
            <div className="text-white bg-blue-inncash xl:w-[550px] xl:h-[235px] md:w-[550px] flex flex-col rounded-b-3xl">
              <div className="xl:w-[490px] py-9 xl:pl-10 px-5">
                <div className="flex justify-center xl:justify-start">
                  <h1 className="font-bold mb-5">
                    Flexibilidade para alteração dos dados
                  </h1>
                </div>
                <p>
                  O InnCash traz os dados do seu ERP e do sistema bancário,
                  porém oferece a flexibilidade que o setor financeiro precisa
                  para fazer edições e inclusões manuais. Altere vencimentos,
                  inclua acréscimos ou descontos e adicione comentários sempre
                  que necessário.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8 xl:mb-0 md:w-[550px]">
            <img
              src={aprovalImage}
              alt="Grupo de pessoas com as mãos unidas"
              className="rounded-t-3xl"
            />
            <div className="text-white bg-purple-400-inncash xl:w-[550px] xl:h-[235px] md:w-[550px] flex flex-col rounded-b-3xl">
              <div className="xl:w-[500px] py-9 xl:pl-10 px-5">
                <div className="flex justify-center xl:justify-start">
                  <h1 className="font-bold mb-6">Aprovação de valores</h1>
                </div>
                <p>
                  Você pode aprovar ou gerenciar quem poderá aprovar previamente
                  o seu fluxo de caixa, garantindo o controle total e impedindo
                  o pagamento de títulos não autorizados.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center mb-8 xl:my-12 md:text-2xl xl:text-3xl">
          {screenSize <= 768 ? (
            <div className="flex flex-col items-center text-lg md:text-2xl mx-6">
              <h1>
                Otimize sua gestão financeira com o <b>InnCash</b> e tenha total{" "}
                <b>controle</b> sobre o seu <b>fluxo de caixa</b>!
              </h1>
            </div>
          ) : (
            <div className="flex flex-col items-center text-3xl md:text-2xl">
              <h1>
                Otimize sua gestão financeira com o <b>InnCash</b> e tenha
              </h1>
              <h1>
                total <b>controle</b> sobre o seu <b>fluxo de caixa</b>!
              </h1>
            </div>
          )}
        </div>
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>

      <Modal
        isOpen={modalIsOpenVideoCashFlow}
        onRequestClose={closeModalVideoCashFlow}
        style={modalStyle}
      >
        <span className="md:flex md:justify-end">
          <button onClick={closeModalVideoCashFlow}>
            <MdClose
              size={23}
              className="transition duration-150 hover:text-blue-inncash hover:scale-110"
            />
          </button>
        </span>
        <span className="flex justify-center">
          {screenSize <= 768 ? (
            <iframe
              width="370"
              height="350"
              src="https://www.youtube.com/embed/d-3AYXn-heo"
              title="Soluções para o Fluxo de Caixa com o Inncash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          ) : (
            <iframe
              width={screenSize <= 1100 ? "790" : "900"}
              height="550"
              src="https://www.youtube.com/embed/d-3AYXn-heo"
              title="Soluções para o Fluxo de Caixa com o Inncash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          )}
        </span>
      </Modal>
    </>
  );
}
