import { Link } from "react-router-dom";

import notebook from "../../../assets/img/notebook-cash-flow.webp";
import dataIcon from "../../../assets/icons/data.svg";
import bankBalanceIcon from "../../../assets/icons/bank-balance.svg";
import personalizationIcon from "../../../assets/icons/personalization.svg";
import editAndInclusionIcon from "../../../assets/icons/editing-and-inclusion.svg";

export default function CashFlow({ tab }) {
  return (
    <div
      className={
        tab !== "tab4"
          ? "hidden"
          : "flex md:flex-row flex-col xl:h-150 items-center md:justify-between md:items-start relative lg:mx-24 xl:mx-0 sm:mx-0"
      }
    >
      <div className="text-white md:mt-12 md:ml-1 mt-4 xl:w-[590px] md:w-[340px] w-[300px] mb-10 flex flex-col items-center md:items-start 2xl:ml-12">
        <h2 className="font-bold xl:text-3xl text-lg mb-5 xl:w-[520px] md:w-[300px]">
          Fluxo de caixa automatizado e personalizado do seu jeito.
        </h2>
        <p className="md:text-[15px] xl:text-xl text-xs xl:w-[590px] md:w-[310px]">
          Tenha uma visão clara e precisa dos seus pagamentos e recebimentos,
          tomando decisões fundamentadas.
        </p>
        <img
          src={notebook}
          alt="Notebook aberto com Inncash na tela"
          className="xl:mt-10 mt-6 xl:ml-5 xl:w-[400px] md:w-[280px]"
        />
      </div>

      <div className="flex flex-col justify-center -mt-48 md:mt-0 md:-ml-3">
        <div className="mt-44 md:mt-10 flex flex-col justify-center items-center">
          <h2 className="md:text-xl text-lg mb-5 text-white">
            Com o <strong>InnCash</strong>, você tem:
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-2 max-[380px]:gap-1 xl:gap-5 md:gap-4">
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={dataIcon}
              alt="Ícone banco azul"
              className="md:mb-5 mb-2 w-[35px] md:w-[62px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Dados atualizados
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              em tempo real
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={bankBalanceIcon}
              alt="Ícone azul de papel com retorno "
              className="md:mb-5 mb-2 w-[25px] md:w-[36px] xl:w-[50px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Saldo bancário atualizado
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              automaticamente todos os dias
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={personalizationIcon}
              alt="Ícone lupa azul"
              className="md:mb-5 mb-2 w-[35px] md:w-[45px] xl:w-[65px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Personalização da visualização
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              conforme suas necessidades
            </p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={editAndInclusionIcon}
              alt="Ícone de papel azul"
              className="md:mb-5 mb-2 w-[25px] md:w-[36px] xl:w-[52px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              Flexibilidade para edição e
            </p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">
              inclusão de dados manualmente
            </p>
          </span>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <Link to="/solucoes-para-fluxo-de-caixa">SAIBA MAIS</Link>
          </button>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <a href="#contact-section">SOLICITAR DEMONSTRAÇÃO</a>
          </button>
        </div>
      </div>
    </div>
  );
}
