import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import ButtonVolumetryForm from "./contexts/ButtonVolumetryForm";
import StepVolumetryForm from "./contexts/StepVolumetryForm";

import Header from "./components/Header";
import Footer from "./components/Footer";
import PaymentMethodsVolumetryForm from "./contexts/PaymentMethodsVolumetryForm";

export default function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [lastStepForm, setLastStepForm] = useState(false);
  const [formsCompleted, setFormsCompleted] = useState(false);
  const [moduleIndex, setModuleIndex] = useState(0);
  const [questionsModules, setQuestionsModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(["default"]);
  const [checkBankSlip, setCheckBankSlip] = useState(false);
  const [checkPix, setCheckPix] = useState(false);
  const [checkCard, setCheckCard] = useState(false);
  const [checkDirectDebit, setCheckDirectDebit] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const locationPath = useLocation();

  async function handleNextModuleQuestions(action) {
    if (moduleIndex <= questionsModules.length - 1) {
      if (action === "advance") {
        setModuleIndex(moduleIndex + 1);
      } else {
        if (moduleIndex > 0) {
          setModuleIndex(moduleIndex - 1);
          verifyLastStepForm();
        } else {
          setLastStepForm(false);
        }
      }
    }
  }

  function verifyLastStepForm() {
    if (lastStepForm) {
      setLastStepForm(false);
    }
  }

  function ScrollToTop(locationPath) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [locationPath]);

    return null;
  }

  useEffect(() => {
    if (moduleIndex === questionsModules.length - 1) {
      setLastStepForm(true);
    }
  }, [moduleIndex, questionsModules]);

  return (
    <>
      <PaymentMethodsVolumetryForm.Provider
        value={{
          checkBankSlip,
          setCheckBankSlip,
          checkPix,
          setCheckPix,
          checkCard,
          setCheckCard,
          checkDirectDebit,
          setCheckDirectDebit,
        }}
      >
        <StepVolumetryForm.Provider
          value={{
            currentStep,
            setCurrentStep,
            selectedModules,
            setSelectedModules,
            selectedPaymentMethods,
            setSelectedPaymentMethods,
          }}
        >
          <ButtonVolumetryForm.Provider
            value={{
              lastStepForm,
              setLastStepForm,
              moduleIndex,
              setModuleIndex,
              questionsModules,
              setQuestionsModules,
              formsCompleted,
              setFormsCompleted,
              handleNextModuleQuestions,
              shareLink,
              setShareLink,
            }}
          >
            <ScrollToTop />
            <nav id="home-section">
              <Header locationPath={locationPath} />
            </nav>
            <Outlet locationPath={locationPath} />
            <Footer locationPath={locationPath} />
          </ButtonVolumetryForm.Provider>
        </StepVolumetryForm.Provider>
      </PaymentMethodsVolumetryForm.Provider>
    </>
  );
}
