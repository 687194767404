import { useContext } from "react";
import { Controller } from "react-hook-form";

import ButtonVolumetryForm from "../../../../contexts/ButtonVolumetryForm";

import PaymentMethods from "../PaymentMethods";
import BillingQuestions from "./BillingQuestions";
import StepVolumetryForm from "../../../../contexts/StepVolumetryForm";
import PaymentMethodsVolumetryForm from "../../../../contexts/PaymentMethodsVolumetryForm";

export default function Questions({
  control,
  methods,
  paymentMethods,
  paymentMethodsContext,
  dataRadioButtonBankSlip,
}) {
  const {
    checkBankSlip,
    setCheckBankSlip,
    checkPix,
    setCheckPix,
    checkCard,
    setCheckCard,
    checkDirectDebit,
    setCheckDirectDebit,
  } = useContext(PaymentMethodsVolumetryForm);
  const { moduleIndex, questionsModules } = useContext(ButtonVolumetryForm);
  const { selectedPaymentMethods, setSelectedPaymentMethods } = useContext(StepVolumetryForm);

  const currentModule = questionsModules[moduleIndex];

  let questionNumber = 1;

  function translateModuleName(moduleName) {
    switch (moduleName) {
      case "billing":
        return "a sua COBRANÇA";
      case "payment":
        return "o seus PAGAMENTOS";
      case "conciliationDDA":
        return "a sua CONCILIAÇÃO DE DDA";
      case "statementReconciliation":
        return "a sua CONCILIAÇÃO DE EXTRATOS BANCÁRIOS";
      case "payroll":
        return "o envio de remessa de FOLHA DE PAGAMENTO";
      default:
        console.error(`Sorry, we are out of ${moduleName}.`);
    }
  }

  function paymentMethodsBuilder(paymentMethod) {
    if (selectedPaymentMethods.includes(paymentMethod)) {
      const updatedPaymentMethods = selectedPaymentMethods.filter((method) => method !== paymentMethod);
      setSelectedPaymentMethods(updatedPaymentMethods);
    } else {
      const updatedPaymentMethods = [...selectedPaymentMethods, paymentMethod];
      setSelectedPaymentMethods(updatedPaymentMethods);
    }
  }

  return (
    <>
      <span className="flex justify-center font-bold text-xl mb-8">
        {currentModule.moduleName === "billingInstructions" ? (
          <div className="flex flex-col items-center text-sm max-[380px]:text-[12spx] md:text-xl">
            <h1>Por favor, conte-nos um pouco sobre o envio de</h1>
            <h1>INSTRUÇÕES PARA BOLETOS DE COBRANÇA (alteração de</h1>
            <h1>vencimento, acréscimo, desconto e baixa)</h1>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row items-center justify-center text-sm md:text-xl">
            <h1>Por favor, conte-nos um pouco sobre</h1>
            <h1 className="ml-[5px]">{translateModuleName(currentModule.moduleName)}</h1>
          </div>
        )}
      </span>

      {currentModule.moduleName === "billing" ? (
        <>
          <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
            <h2 className="font-bold">
              Selecione o(s) método(s) de pagamento que você deseja implementar com o InnCash:
            </h2>
            <PaymentMethods
              methods={methods}
              paymentMethods={paymentMethods}
              paymentMethodsContext={paymentMethodsContext}
              paymentMethodsBuilder={paymentMethodsBuilder}
              setCheckBankSlip={setCheckBankSlip}
              checkBankSlip={checkBankSlip}
              setCheckPix={setCheckPix}
              checkPix={checkPix}
              setCheckCard={setCheckCard}
              checkCard={checkCard}
              setCheckDirectDebit={setCheckDirectDebit}
              checkDirectDebit={checkDirectDebit}
            />
          </div>
          <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
            <div>
              <h2 className="font-bold">
                Qual a quantidade média mensal de títulos a serem enviados para cobrança?
                <span className="font-bold text-xs text-red-500 ml-2">
                  {methods.formState.errors.billing && methods.formState.errors.billing.default?.message}
                </span>
              </h2>
              <p>(Boleto, PIX, Cartão ou Débito automático)</p>
              <Controller
                name={"billing.default"}
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <input {...field} type="text" className="border-b-2 border-black w-full focus:outline-none mt-2" />
                )}
              />
            </div>
          </div>
          <BillingQuestions
            methods={methods}
            control={control}
            currentModule={currentModule}
            checkBankSlip={checkBankSlip}
            checkPix={checkPix}
            checkCard={checkCard}
            checkDirectDebit={checkDirectDebit}
            dataRadioButtonBankSlip={dataRadioButtonBankSlip}
          />
        </>
      ) : (
        <>
          {currentModule.questions.map((question, index) => {
            const number = (index + 1).toString();
            const moduleName = currentModule.moduleName;
            const questionName = `${moduleName}.question${number}`;
            const questionError = `question${number}`;
            const moduleErrors = methods.formState.errors[moduleName];

            return (
              <>
                <div key={question.id} className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
                  <h2 className="font-bold">
                    {questionNumber++}.{question.question}
                    <span className="font-bold text-xs text-red-500 ml-2">
                      {moduleErrors && moduleErrors[questionError]?.message}
                    </span>
                  </h2>
                  <p>{question.additionalInfo}</p>
                  <Controller
                    name={questionName}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        className="border-b-2 border-black w-full focus:outline-none mt-2"
                      />
                    )}
                  />
                </div>
              </>
            );
          })}
        </>
      )}
    </>
  );
}
