import { useRef, useState, useEffect } from "react";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { BiMenu } from "react-icons/bi";
import { MdClose } from "react-icons/md";

import logoInncashBlue from "../assets/img/logotipo-inncash-blue.svg";
import { Link } from "react-router-dom";

export default function Header({ locationPath }) {
  const screenSize = window.screen.width;
  const [solutionsIsOpen, setSolutionsIsOpen] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const linkRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    closeMenu();
  }, [locationPath]);

  function forwardToForms() {
    if (locationPath.pathname === "/quem-somos" || locationPath.pathname === "/seja-um-parceiro") {
      setTimeout(() => {
        linkRef.current.click();
      }, 1500);
    } else {
      linkRef.current.click();
    }
  }

  function closeMenu() {
    setMobileMenuIsOpen(false);
    setSolutionsIsOpen(false);
  }

  return (
    <>
      {/* hidden tag responsible for directing to the contacts section */}
      <a href="#contact-section" ref={linkRef} className="hidden">
        forwardToForms
      </a>
      {screenSize <= 1024 ? (
        <div>
          <div className="flex justify-between h-20 w-full xl:px-10 md:text-sm xl:text-lg px-5 font-bold bg-white fixed top-0 left-0 shadow-md z-50">
            <Link to="/" className="flex items-center">
              <img src={logoInncashBlue} alt="Logo azul do Inncash" className="w-[140px] h-[33px]" />
            </Link>
            <div className="flex items-center">
              {mobileMenuIsOpen === true ? (
                <MdClose size={28} onClick={closeMenu} />
              ) : (
                <BiMenu size={30} onClick={() => setMobileMenuIsOpen(!mobileMenuIsOpen)} />
              )}
            </div>
          </div>
          <div
            className={
              mobileMenuIsOpen
                ? "bg-white rounded-b-xl w-full flex flex-col items-center p-4 font-bold gap-4 text-lg fixed top-18 left-0 shadow-md z-40"
                : "hidden"
            }
          >
            <button className="mt-2" onClick={() => setSolutionsIsOpen(!solutionsIsOpen)}>
              {solutionsIsOpen === true ? (
                <span className="flex ml-4">
                  Soluções{" "}
                  <MdKeyboardArrowUp
                    onClick={() => setSolutionsIsOpen(!solutionsIsOpen)}
                    size={25}
                    className="mt-[2px]"
                  />
                </span>
              ) : (
                <span className="flex ml-4">
                  Soluções{" "}
                  <MdKeyboardArrowDown
                    onClick={() => setSolutionsIsOpen(!solutionsIsOpen)}
                    size={25}
                    className="mt-[2px]"
                  />
                </span>
              )}
            </button>
            <div className={solutionsIsOpen === true ? "flex flex-col ml-14 gap-1 text-[14px]" : "hidden"}>
              <Link to="/solucoes-em-pagamentos">Pagamentos</Link>
              <Link to="/solucoes-em-cobrancas">Cobranças</Link>
              <Link to="/solucoes-para-conciliacao-bancaria">Conciliação Bancária</Link>
              <Link to="/solucoes-para-fluxo-de-caixa">Fluxo de Caixa</Link>
              <Link to="/analise-de-credito">Análise de Crédito</Link>
              <Link to="/gateway-de-pagamento">Gateway de Pagamento</Link>
            </div>
            <Link to="/quem-somos">Quem somos</Link>
            <Link to="/seja-um-parceiro">Seja um Parceiro</Link>
            <Link to="https://blog.inn.cash/" target="_blank">
              Blog
            </Link>
            <Link
              to={locationPath.pathname === "/quem-somos" || "/seja-um-parceiro" ? "/" : "#contact-section"}
              onClick={forwardToForms}
            >
              Contato
            </Link>
            <Link to="https://app.inn.cash/login" target="_blank">
              Acessar
            </Link>
            <Link
              to={locationPath.pathname === "/quem-somos" || "/seja-um-parceiro" ? "/" : "#contact-section"}
              onClick={forwardToForms}
              className="py-3 px-8 rounded-3xl text-white bg-blue-inncash hover:bg-blue-600 transition duration-150 cursor-pointer"
            >
              Solicitar Demonstração
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex justify-between h-20 w-full xl:px-20 min-[1700px]:px-36 md:text-sm xl:text-lg px-5 md:px-2 font-bold bg-white fixed top-0 left-0 shadow-md z-50">
          <Link to="/" className="flex items-center">
            <img src={logoInncashBlue} alt="Logo azul do Inncash" className="w-[140px] h-[33px]" />
          </Link>
          <ul className="hidden lg:flex items-center justify-center space-x-11 text-base">
            <li>
              {!solutionsIsOpen ? (
                <button
                  onClick={() => setSolutionsIsOpen(!solutionsIsOpen)}
                  className="flex items-center hover:text-blue-inncash active:text-blue-inncash transition duration-150"
                >
                  Soluções
                  <MdKeyboardArrowDown size={23} className="mt-1" />
                </button>
              ) : (
                <button
                  onClick={() => setSolutionsIsOpen((previusState) => !previusState)}
                  className="flex items-center text-blue-inncash transition active:text-blue-inncash duration-150"
                >
                  Soluções
                  <MdKeyboardArrowUp size={23} className="mt-1 text-blue-inncash" />
                </button>
              )}

              {solutionsIsOpen && (
                <div ref={menuRef} className="w-[225px] pt-[26px] pb-2 -mx-7 absolute rounded-lg bg-white">
                  <Link
                    to="/solucoes-em-pagamentos"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Pagamentos
                  </Link>

                  <Link
                    to="/solucoes-em-cobrancas"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Cobranças
                  </Link>
                  <Link
                    to="/solucoes-para-conciliacao-bancaria"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Conciliação Bancária
                  </Link>
                  <Link
                    to="/solucoes-para-fluxo-de-caixa"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Fluxo de Caixa
                  </Link>
                  <Link
                    to="/analise-de-credito"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Análise de Crédito
                  </Link>
                  <Link
                    to="/gateway-de-pagamento"
                    className="block w-full text-[17px] pl-7 py-5 bg-white hover:text-white hover:bg-purple-400-inncash transition duration-150 text-base"
                  >
                    Gateway de Pagamento
                  </Link>
                </div>
              )}
            </li>
            <li className="hover:text-blue-600 transition duration-150">
              <Link to="/quem-somos">Quem somos</Link>
            </li>
            <li className="hover:text-blue-600 transition duration-150">
              <Link to="/seja-um-parceiro">Seja um Parceiro</Link>
            </li>
            <li className="hover:text-blue-600 transition duration-150">
              <Link to="https://blog.inn.cash/" target="_blank">
                Blog
              </Link>
            </li>
            <li className="hover:text-blue-600 transition duration-150">
              <Link
                to={
                  locationPath.pathname === "/quem-somos" || locationPath.pathname === "/seja-um-parceiro"
                    ? "/"
                    : "#contact-section"
                }
                onClick={forwardToForms}
              >
                Contato
              </Link>
            </li>
          </ul>

          <div className="hidden lg:flex justify-end">
            <ul className="flex items-center space-x-7 text-base">
              <li>
                <a
                  href="https://app.inn.cash/login"
                  target="_blank"
                  className="hover:text-blue-inncash transition duration-150"
                  rel="noreferrer"
                >
                  Acessar
                </a>
              </li>
              <li className="py-3 px-8 rounded-3xl text-white bg-blue-inncash hover:bg-blue-600 transition duration-150 cursor-pointer">
                <Link
                  to={
                    locationPath.pathname === "/quem-somos" || locationPath.pathname === "/seja-um-parceiro"
                      ? "/"
                      : "#contact-section"
                  }
                  onClick={forwardToForms}
                >
                  Solicitar Demonstração
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
