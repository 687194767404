import { useState } from "react";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";

import ContactForms from "../../components/ContactForms/ContactForms";

import videoBankConciliation from "../../assets/thumbs/video-bank-conciliation.webp";
import playButton from "../../assets/icons/play.svg";
import extractIcon from "../../assets/icons/extract.svg";
import inconsistencyIcon from "../../assets/icons/inconsistency.svg";
import printScreenBankConciliation from "../../assets/img/print-screen-bank-conciliation.webp";
import focusImage from "../../assets/img/focus-bank-conciliation.webp";
import multiCompaniesImage from "../../assets/img/multi-companies-bank-conciliation.webp";

export default function BankConciliation({ modalStyle, screenSize }) {
  const [modalIsOpenVideoBankConciliation, setModalIsOpenVideoBankConciliation] = useState(false);

  function openModalVideoBankConciliation() {
    setModalIsOpenVideoBankConciliation(true);
  }

  function closeModalVideoBankConciliation() {
    setModalIsOpenVideoBankConciliation(false);
  }

  return (
    <>
      <section className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between md:py-14 py-10 items-center min-[1700px]:px-36 xl:px-14 lg:px-6 md:px-4 md:mt-20 mt-[81px]">
        <div className="flex flex-col md:items-start items-center text-white md:my-12 md:ml-3 xl:ml-0">
          <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">Soluções para</h1>
          <h1 className="font-bold text-4xl lg:text-6xl min-[1700px]:text-7xl">Conciliação de</h1>
          <h1 className="font-bold text-4xl lg:text-6xl min-[1700px]:text-7xl md:mb-7 mb-4">Extratos Bancários</h1>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">Automatize a conciliação bancária das</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">contas de sua empresa e poupe tempo do</p>
          <p className="text-sm xl:text-lg mb-6 min-[1700px]:text-xl">seu time.</p>
          <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
            <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
          </button>
        </div>
        <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
          <img
            src={videoBankConciliation}
            alt="Thumbnail vídeo do youtube soluções de pagamentos Inncash"
            className="rounded-3xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
          />
          <img
            src={playButton}
            onClick={openModalVideoBankConciliation}
            alt="Botão de play branco redondo"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
          />
        </span>
      </section>

      <section className="mt-5 md:mt-12">
        {screenSize <= 768 ? (
          <div className="font-bold text-lg flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Conciliação bancária automatizada</h1>
          </div>
        ) : (
          <div className="md:flex justify-center mb-8">
            <h1 className="md:text-xl xl:text-2xl font-bold">Conciliação bancária automatizada</h1>
          </div>
        )}
        <div className="text-lg md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Saiba como podemos melhorar o seu</h1>
          <h1>
            processo de <b>fechamento de caixa</b>
          </h1>
        </div>

        <div className="md:grid grid-cols-2 xl:mx-24 mx-3">
          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img src={extractIcon} alt="Ícone azul de cifrão" className="w-12 md:w-14 xl:mt-14" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">
                Extrato disponível antes de amanhecer!
              </h2>
              <p>Antes das 6h da manhã, o InnCash já recebe os</p>
              <p>extratos com as movimentações bancárias e</p>
              <p>realiza uma conferência com os lançamentos já</p>
              <p>realizados no ERP. Esse processo é totalmente</p>
              <p>automático, não havendo intervenção humana.</p>
              <p className="mt-5">E mais: os saldos bancários já ficam disponíveis</p>
              <p>logo cedo no fluxo de caixa.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img
              src={inconsistencyIcon}
              alt="ïcone azul de cifrão dentro de uma núvem"
              className="w-18 md:w-20 xl:mt-14"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Apontamos qualquer inconsistência</h2>
              <p>A conciliação automática do InnCash identifica de</p>
              <p>forma rápida e eficiente qualquer discrepância entre o</p>
              <p>extrato bancário e registros financeiros da empresa.</p>
              <p className="mt-5">O sistema apresenta o detalhamento dos</p>
              <p>lançamentos do banco X do ERP, realiza a</p>
              <p>conciliação de maneira precisa e traz em destaque</p>
              <p>qualquer diferença entre eles.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center">
        <div className="bg-gray-100-inncash py-10 xl:px-20 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center mx-4">
          <h1 className="text-blue-inncash font-bold text-xl lg:text-2xl xl:text-3xl mb-8 lg:mb-0 lg:w-[260px] 2xl:mr-56 xl:mr-28">
            Nós lançamos as tarifas para você
          </h1>
          <p className="lg:w-[650px] lg:text-sm xl:text-normal">
            Todas tarifas bancárias da empresa são previamente mapeadas e configuradas para lançamento automático no seu
            ERP. E não apenas tarifas! Demais movimentações recorrentes podem ser lançadas, tais como
            resgates/aplicações e transferências entre contas da própria empresa.
          </p>
        </div>
      </section>

      <section className="mb-10 xl:mb-16 mx-4 xl:mx-40">
        <div>
          <img
            src={printScreenBankConciliation}
            alt="Print da tela de pagamentos dentro do Inncash"
            className="rounded-md"
          />
        </div>
      </section>

      <section className="md:mt-16 mt-14 mx-5">
        <div className="xl:flex xl:flex-row justify-center xl:gap-5 md:grid place-items-center flex flex-col items-center">
          <div className="mb-8 xl:mb-0 md:w-[550px]">
            <img src={multiCompaniesImage} alt="Mulher de óculos mexendo em notebook" className="rounded-t-3xl" />
            <div className="text-white bg-blue-inncash xl:w-[550px] xl:h-[235px] md:w-[550px] flex flex-col rounded-b-3xl">
              <div className="xl:w-[520px] py-9 xl:pl-10 px-5">
                <div className="flex justify-center xl:justify-start">
                  <h1 className="font-bold mb-5">Multiempresas e Multibancos</h1>
                </div>
                <p>
                  Todas as <b>contas</b> de todos os <b>bancos</b> de todas as <b>empresas</b>.
                </p>
                <p className="mt-5">
                  Tudo o que você precisa para conciliar o seu caixa centralizado em uma única plataforma.
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8 xl:mb-0 md:w-[550px]">
            <img src={focusImage} alt="Grupo de pessoas com as mãos unidas" className="rounded-t-3xl" />
            <div className="text-white bg-purple-400-inncash xl:w-[550px] xl:h-[235px] md:w-[550px] flex flex-col rounded-b-3xl">
              <div className="xl:w-[500px] py-9 xl:pl-10 px-5">
                <div className="flex justify-center xl:justify-start">
                  <h1 className="font-bold mb-6">Seu foco vai para as divergências</h1>
                </div>
                <p>
                  Com todo o processo recorrente automatizado, sua atenção se concentrará apenas em lançamentos não
                  previstos, tais como depósitos não identificados. E mesmo esses poderão ser lançados ou baixados a
                  partir da tela de conciliação do InnCash. Menos cliques, menos tempo desperdiçado!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center mb-8 xl:my-12 md:text-2xl xl:text-3xl">
          {screenSize <= 768 ? (
            <div className="flex flex-col items-center text-lg">
              <h1>
                Precisa de <b>agilidade</b> e <b>pontualidade</b>
              </h1>
              <h1>na prestação de contas?</h1>
              <h1 className="mt-2">
                O <b>InnCash</b> vai te ajudar com isso.
              </h1>
            </div>
          ) : (
            <div className="flex flex-col items-center text-3xl md:text-2xl">
              <h1>
                Precisa de <b>agilidade</b> e <b>pontualidade</b> na prestação de contas?
              </h1>
              <h1>
                O <b>InnCash</b> vai te ajudar com isso.
              </h1>
            </div>
          )}
        </div>
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>

      <Modal
        isOpen={modalIsOpenVideoBankConciliation}
        onRequestClose={closeModalVideoBankConciliation}
        style={modalStyle}
      >
        <span className="md:flex md:justify-end">
          <button onClick={closeModalVideoBankConciliation}>
            <MdClose size={23} className="transition duration-150 hover:text-blue-inncash hover:scale-110" />
          </button>
        </span>
        <span className="flex justify-center">
          {screenSize <= 768 ? (
            <iframe
              width="370"
              height="350"
              src="https://www.youtube.com/embed/GjksL70aCCk"
              title="Soluções em Conciliação Bancária com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          ) : (
            <iframe
              width={screenSize <= 1100 ? "790" : "900"}
              height="550"
              src="https://www.youtube.com/embed/GjksL70aCCk"
              title="Soluções em Conciliação Bancária com o InnCash"
              frameborder="0"
              allow="autoplay"
            ></iframe>
          )}
        </span>
      </Modal>
    </>
  );
}
