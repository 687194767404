import { Link } from "react-router-dom";

import notebook from "../../../assets/img/notebook-credit-analysis.webp.webp";
import creditRulesIcon from "../../../assets/icons/credit-rules.svg";
import decisionMakingIcon from "../../../assets/icons/decision-making.svg";
import financialSheetIcon from "../../../assets/icons/financial-sheet.svg";
import agilityInReleaseIcon from "../../../assets/icons/agility-in-release.svg";

export default function CreditAnalysis({ tab }) {
  return (
    <div
      className={
        tab !== "tab5"
          ? "hidden"
          : "flex md:flex-row flex-col xl:h-150 items-center md:justify-between md:items-start relative lg:mx-24 xl:mx-0 sm:mx-0"
      }
    >
      <div className="text-white md:mt-12 md:ml-1 mt-4 xl:w-[590px] md:w-[340px] w-[300px] mb-10 flex flex-col items-center md:items-start 2xl:ml-12">
        <h2 className="font-bold xl:text-3xl text-lg mb-5 xl:w-[520px] md:w-[300px]">
          Análise de crédito de forma automatizada e eficiente.
        </h2>
        <p className="md:text-[15px] xl:text-xl text-xs xl:w-[550px] md:w-[280px]">
          Reunimos todos os dados necessários para que a sua decisão seja embasada e confiável.
        </p>
        <img
          src={notebook}
          alt="Notebook aberto com Inncash na tela"
          className="xl:mt-10 mt-6 xl:ml-5 xl:w-[400px] md:w-[280px]"
        />
      </div>

      <div className="flex flex-col justify-center -mt-48 md:mt-0 md:-ml-3">
        <div className="mt-44 md:mt-10 flex flex-col justify-center items-center">
          <h2 className="md:text-xl text-lg mb-5 text-white">
            Com o <strong>InnCash</strong>, você tem:
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-2 max-[380px]:gap-1 xl:gap-5 md:gap-4">
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={creditRulesIcon}
              alt="Ícone azul de papael com um checklist"
              className="md:mb-5 mb-2 w-[35px] md:w-[50px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">Regras de crédito com</p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">critérios personalizados</p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={decisionMakingIcon}
              alt="Ícone azul de usuário "
              className="md:mb-5 mb-2 w-[33px] md:w-[36px] xl:w-[50px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">Tomadas de decisão efetivas,</p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">reduzindo riscos e erros</p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={financialSheetIcon}
              alt="Ícone azul de gráfico de pizza"
              className="md:mb-5 mb-2 w-[35px] md:w-[36px] xl:w-[50px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">Análise de dados por meio</p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">de gráficos precisos e seguros</p>
          </span>
          <span className="xl:w-75 xl:h-43 md:w-[225px] md:h-[130px] w-[177px] max-[380px]:w-[167px] h-[120px] bg-white rounded-2xl text-blue-inncash font-medium flex flex-col items-center justify-center">
            <img
              src={agilityInReleaseIcon}
              alt="Ícone azul de dinheiro em moedas e papel"
              className="md:mb-5 mb-2 w-[35px] md:w-[40px] xl:w-[52px]"
            />
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">Agilidade no processo de</p>
            <p className="xl:text-[18px] md:text-[14px] text-[11px]">liberação de crédito</p>
          </span>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <Link to="/analise-de-credito">SAIBA MAIS</Link>
          </button>
          <button className="rounded-3xl bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover text-white md:py-3 py-1 md:text-[13px] text-[11px] mt-1 font-bold">
            <a href="#contact-section">SOLICITAR DEMONSTRAÇÃO</a>
          </button>
        </div>
      </div>
    </div>
  );
}
