import { useState } from "react";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination, Autoplay } from "swiper";

import ContactForms from "../../components/ContactForms/ContactForms";
import Payment from "../Home/Tabs/Payment";
import Billing from "../Home/Tabs/Billing";
import BankConciliation from "../Home/Tabs/BankConciliation";
import CashFlow from "../Home/Tabs/CashFlow";
import CreditAnalysis from "./Tabs/CreditAnalysis";
import BanksMobile from "./Banks/BanksMobile";
import BanksDesktop from "./Banks/BanksDesktop";
import Customers from "./Customers";
import Slider from "./Slider";

import playButton from "../../assets/icons/play.svg";
import notebookHomeDesktop from "../../assets/img/notebook-home-desktop.webp";
import notebookHomeMobile from "../../assets/img/notebook-home-mobile.webp";
import videoEfficientFinancial from "../../assets/thumbs/video-efficient-financial.webp";
import flowchartDesktop from "../../assets/img/flowchart-home-desktop.svg";
import flowchartMobile from "../../assets/img/flowchart-home-mobile.svg";
import videoColorminas from "../../assets/thumbs/video-colorminas.webp";
import graphic80 from "../../assets/img/graphic-home-80.svg";
import graphic50 from "../../assets/img/graphic-home-50.svg";

export default function Home({ customersList, socialProofList, screenSize, modalStyle }) {
  const [modalIsOpenVideoEfficientFinancial, setModalIsOpenVideoEfficientFinancial] = useState(false);
  const [modalIsOpenVideoColorminas, setModalIsOpenVideoColorminas] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [tab, setTab] = useState("tab1");

  function openModalVideoEfficientFinancial() {
    setModalIsOpenVideoEfficientFinancial(true);
  }

  function closeModalVideoEfficientFinancial() {
    setModalIsOpenVideoEfficientFinancial(false);
  }

  function openModalVideoColorminas() {
    setModalIsOpenVideoColorminas(true);
  }

  function closeModalVideoColorminas() {
    setModalIsOpenVideoColorminas(false);
  }

  function handleChangeTab(data) {
    setTab(data);
  }

  function handleTab(tab) {
    let tabActive = "";

    switch (tab) {
      case "tab1":
        tabActive = "tab1";
        break;
      case "tab2":
        tabActive = "tab2";
        break;
      case "tab3":
        tabActive = "tab3";
        break;
      case "tab4":
        tabActive = "tab4";
        break;
      case "tab5":
        tabActive = "tab5";
        break;
      default:
        tabActive = "tab1";
    }

    setActiveTab(tabActive);
    handleChangeTab(tabActive);
  }

  return (
    <>
      <main>
        <section className="mt-[81px] md:mt-20">
          <div className="bg-inncash lg:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between h-[calc(100vh-80px)] items-center xl:pl-20 min-[1700px]:pl-36 lg:pl-6 md:pl-6">
            <div className="flex flex-col items-center md:items-start text-white">
              <p className="font-bold text-3xl lg:text-5xl md:mb-7 min-[1700px]:text-6xl mb-4">Seja Estratégico.</p>
              <p className="font-bold text-xl md:text-2xl min-[1700px]:text-3xl">Soluções para automatizar</p>
              <p className="font-bold text-xl md:text-2xl min-[1700px]:text-3xl">os processos financeiros</p>
              <p className="font-bold text-xl md:text-2xl md:mb-4 mb-3 min-[1700px]:text-3xl">de grandes empresas.</p>
              <p className="text-sm xl:text-lg min-[1700px]:text-xl">
                Integre, gerencie e automatize seu financeiro com
              </p>
              <p className="text-sm xl:text-lg max-w-[400px] md:mb-7 mb-5 min-[1700px]:text-xl">
                segurança, agilidade e transparência.
              </p>
              <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
                <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
              </button>
            </div>
            <div className="flex md:justify-end justify-center">
              <img
                src={screenSize >= 768 ? notebookHomeDesktop : notebookHomeMobile}
                alt="Notebook aberto com Inncash na tela"
                className="w-72 max-[360px]:w-60 md:w-[450px] lg:w-[600px] xl:w-[700px] min-[1700px]:w-[800px] mt-6 md:mt-0"
              />
            </div>
          </div>
        </section>

        <section className="mt-5 md:mt-14">
          <div className="flex flex-col md:flex-row justify-between md:justify-between mx-2 sm:px-14 md:px-2 lg:px-10 xl:px-18 min-[1700px]:px-36">
            <div className="mb-6 md:w-[350px] lg:w-[460px] flex flex-col justify-center items-center md:items-start">
              <h1 className="font-bold xl:text-3xl text-xl">
                Eleve a <span className="text-blue-inncash xl:text-3xl text-xl">Performance</span> do seu time.
              </h1>
              <p className="font-regular text-md mt-5">
                Conectamos as operações de pagamentos e recebimentos do seu sistema de gestão (ERP) com as principais
                instituições bancárias via EDI Financeiro (VAN) ou API.
              </p>
              <p className="font-regular text-base mt-5">
                Com isso, seus processos financeiros ganham agilidade e suas operações tornam-se muito mais eficientes e
                estratégicas.
              </p>
            </div>

            <div>
              <span className="relative">
                <img
                  src={videoEfficientFinancial}
                  alt="Thumbnail vídeo do youtube financeiro eficiente Inncash"
                  className="rounded-2xl md:w-[430px] xl:w-[600px]"
                />
                <img
                  src={playButton}
                  alt="Botão de play branco redondo"
                  onClick={openModalVideoEfficientFinancial}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
                />
              </span>
            </div>

            <Modal
              isOpen={modalIsOpenVideoEfficientFinancial}
              onRequestClose={closeModalVideoEfficientFinancial}
              style={modalStyle}
            >
              <span className="md:flex md:justify-end">
                <button onClick={closeModalVideoEfficientFinancial}>
                  <MdClose size={23} className="transition duration-150 hover:text-blue-inncash hover:scale-110" />
                </button>
              </span>
              <span className="flex justify-center">
                {screenSize <= 768 ? (
                  <iframe
                    width="370"
                    height="350"
                    src="https://www.youtube.com/embed/cxsCAI8c9-A"
                    title="Seja Eficiente, Seja InnCash!"
                    frameborder="0"
                    allow="autoplay"
                  ></iframe>
                ) : (
                  <iframe
                    width={screenSize <= 1100 ? "790" : "900"}
                    height="550"
                    src="https://www.youtube.com/embed/cxsCAI8c9-A"
                    title="Seja Eficiente, Seja InnCash!"
                    frameborder="0"
                    allow="autoplay"
                  ></iframe>
                )}
              </span>
            </Modal>
          </div>
        </section>

        <section className="mt-10 xl:mt-14" id="solutions-section">
          <div>
            <ul className="xl:mx-10 min-[1700px]:mx-24 mx-2 rounded-t-3xl bg-gray-200-tabs font-bold md:h-[72px] h-14 grid grid-cols-5">
              <button
                className={
                  activeTab === "tab1"
                    ? "rounded-t-3xl bg-purple-400-inncash"
                    : "rounded-t-3xl hover:bg-purple-200-inncash transition duration-150 "
                }
              >
                <li
                  className={
                    activeTab === "tab1"
                      ? "text-white text-[10px] md:text-[14px]"
                      : "text-gray-400-tabs hover:text-white text-[10px] md:text-[14px] w-full h-full flex items-center justify-center"
                  }
                  onClick={() => handleTab("tab1")}
                >
                  PAGAMENTOS
                </li>
              </button>
              <button
                className={
                  activeTab === "tab2"
                    ? "rounded-t-3xl bg-purple-400-inncash"
                    : "rounded-t-3xl hover:bg-purple-200-inncash transition duration-150"
                }
              >
                <li
                  className={
                    activeTab === "tab2"
                      ? "text-white text-[10px] md:text-[14px]"
                      : "text-gray-400-tabs hover:text-white text-[10px] md:text-[14px] w-full h-full flex items-center justify-center"
                  }
                  onClick={() => handleTab("tab2")}
                >
                  COBRANÇAS
                </li>
              </button>
              <button
                className={
                  activeTab === "tab3"
                    ? "rounded-t-3xl bg-purple-400-inncash"
                    : "rounded-t-3xl hover:bg-purple-200-inncash transition duration-150"
                }
              >
                <li
                  className={
                    activeTab === "tab3"
                      ? "text-white text-[10px] md:text-[14px]"
                      : "text-gray-400-tabs hover:text-white text-[10px] md:text-[14px] w-full h-full flex items-center justify-center"
                  }
                  onClick={() => handleTab("tab3")}
                >
                  CONCILIAÇÃO BANCÁRIA
                </li>
              </button>
              <button
                className={
                  activeTab === "tab4"
                    ? "rounded-t-3xl bg-purple-400-inncash"
                    : "rounded-t-3xl hover:bg-purple-200-inncash transition duration-150"
                }
              >
                <li
                  className={
                    activeTab === "tab4"
                      ? "text-white text-[10px] md:text-[14px]"
                      : "text-gray-400-tabs hover:text-white text-[10px] md:text-[14px] w-full h-full flex items-center justify-center"
                  }
                  onClick={() => handleTab("tab4")}
                >
                  FLUXO DE CAIXA
                </li>
              </button>
              <button
                className={
                  activeTab === "tab5"
                    ? "rounded-t-3xl bg-purple-400-inncash"
                    : "rounded-t-3xl hover:bg-purple-200-inncash transition duration-150"
                }
              >
                <li
                  className={
                    activeTab === "tab5"
                      ? "text-white text-[10px] md:text-[14px]"
                      : "text-gray-400-tabs hover:text-white text-[10px] md:text-[14px] w-full h-full flex items-center justify-center"
                  }
                  onClick={() => handleTab("tab5")}
                >
                  ANÁLISE DE CRÉDITO
                </li>
              </button>
            </ul>
          </div>

          <div className="bg-blue-inncash rounded-b-3xl md:h-[500px] xl:h-[600px] h-[740px] xl:mx-10 min-[1700px]:mx-24 xl:py-10 xl:px-6 2xl:p-10 md:p-2 mx-2 relative">
            <Payment tab={tab} />
            <Billing tab={tab} />
            <BankConciliation tab={tab} />
            <CashFlow tab={tab} />
            <CreditAnalysis tab={tab} />
          </div>
        </section>

        <section className="mt-10 md:mt-14">
          <div>
            <div className="md:mx-6 lg:mx-10 xl:mx-36 min-[1700px]:mx-64 flex flex-col">
              <div className=" font-bold md:text-2xl max-[380px]:text-[12px] text-[13px] flex flex-col items-center mb-5">
                <h1>Faça como estas grandes marcas e mais de 200 outras que já</h1>
                <h1 className="text-blue-inncash">
                  automatizam pagamentos e cobranças com o InnCash
                  <span className="text-black">.</span>
                </h1>
              </div>
              <div className="grid lg:grid-cols-7 grid-cols-2 place-items-center">
                {customersList.map((customer) => {
                  return <Customers key={customer.id} href={customer.href} src={customer.src} alt={customer.alt} />;
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="md:mt-1 xl:mt-14 mt-4">
          <div className="bg-gray-100-inncash md:h-[280px] h-[365px] lg:px-16 xl:px-28 min-[1700px]:px-40 md:px-3">
            <div className="flex justify-center">
              <h1 className="text-blue-inncash font-bold text-3xl mr-2 mt-4 md:mt-16">InnCash</h1>
              <h1 className="font-bold text-3xl mt-4 md:mt-16">em Números</h1>
            </div>
            <div className="md:flex justify-between">
              <div className="flex flex-col items-center mt-8">
                <h1 className="text-blue-inncash font-bold xl:text-5xl md:text-4xl text-4xl">+ 6.2 Bilhões</h1>
                <p className="font-regular xl:text-xl md:text-lg text-lg">de transações em 2022</p>
              </div>
              <div className="flex flex-col items-center mt-8 md:-ml-8">
                <h1 className="text-blue-inncash font-bold xl:text-5xl md:text-4xl text-4xl">+ 8.2 mil</h1>
                <p className="font-regular xl:text-xl md:text-lg text-lg">Horas ganhas para o setor financeiro</p>
              </div>
              <div className="flex flex-col items-center mt-8">
                <h1 className="text-blue-inncash font-bold xl:text-5xl md:text-4xl text-4xl">+ 794 mil</h1>
                <p className="font-regular xl:text-xl md:text-lg text-lg">Títulos pagos e recebidos</p>
              </div>
            </div>
          </div>
        </section>

        <section className="md:mt-14 mt-10">
          <div className="flex flex-col items-center justify-center">
            {screenSize <= 768 ? (
              <div className="flex flex-col items-center justify-center mb-8">
                <h1 className="font-bold mb-4">
                  <span className="text-blue-inncash">+200</span> Instituições Financeiras Conectadas
                </h1>
                <p className="text-sm max-[380px]:text-[13px]">
                  O InnCash é um hub de integrações bancárias, que permite
                </p>
                <p className="text-sm max-[380px]:text-[13px]">conectar de forma simples e segura</p>
                <p className="text-sm mt-2 max-[380px]:text-[13px]">o seu negócio com as instituições financeiras</p>
                <p className="text-sm max-[380px]:text-[13px]">da sua preferência</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center mb-12">
                <h1 className="font-bold md:text-3xl mb-4">
                  <span className="text-blue-inncash">+200</span> Instituições Financeiras Conectadas
                </h1>
                <p className="text-sm md:text-lg">
                  O InnCash é um hub de integrações bancárias, que permite conectar de forma simples e segura
                </p>
                <p className="text-sm md:text-lg">o seu negócio com as instituições financeiras da sua preferência</p>
              </div>
            )}
            <div className="md:mx-4">
              {screenSize <= 768 ? (
                <img
                  src={flowchartMobile}
                  alt="Fluxo de como ocorre a conexão entre o seu ERP e os bancos através do Inncash"
                  className="w-[200px]"
                />
              ) : (
                <img
                  src={flowchartDesktop}
                  alt="Fluxo de como ocorre a conexão entre o seu ERP e os bancos através do Inncash"
                  className="xl:w-[900px] lg:w-[750px]"
                />
              )}
            </div>
          </div>
        </section>

        <section className="mt-10 md:mt-14 ">
          <div className="font-bold flex flex-col lg:flex-row lg:justify-between items-center text-xl">
            <div className="flex flex-col items-center justify-center lg:items-start mb-10 xl:ml-24 lg:ml-6 min-[1700px]:ml-40 md:text-3xl xl:text-3xl lg:text-[23px] text-xl md:mb-10">
              <h1>Bancos e Adquirentes com os</h1>
              <h1>
                quais o InnCash possui <span className="text-blue-inncash">integração</span>
              </h1>
            </div>
            <div>{screenSize <= 1024 ? <BanksMobile /> : <BanksDesktop />}</div>
          </div>
        </section>

        <div className="bg-blue-700-demonstration mt-10 md:mt-20 mx-3 xl:mx-16 min-[1700px]:mx-40 py-3 xl:px-24 lg:px-2 md:px-2 font-bold h-[230px] md:h-[170px] rounded-3xl flex flex-col justify-center md:flex-row md:items-center md:justify-between">
          <div className="xl:text-xl text-lg flex flex-col lg:flex-row items-center lg:items-start text-white">
            <h2>Solicite um contato e descubra como</h2>
            <h2>
              <span className="text-blue-200-inncash mr-1 ml-1">automatizar o financeiro</span>
              da sua empresa
            </h2>
          </div>
          <div className="rounded-3xl text-white bg-green-500-attention transition duration-150 hover:bg-green-500-attention-hover px-3 xl:px-16 h-8 md:h-11 text-md xl:-ml-12 mx-3 flex items-center justify-center mt-6 md:mt-0">
            <button id="call-submit" type="submit">
              <a href="#contact-section">SOLICITAR DEMONSTRAÇÃO</a>
            </button>
          </div>
        </div>

        <section className="mt-10 md:mt-14">
          <div className="xl:px-50 lg:px-10 md:px-12">
            <div className="font-bold lg:text-3xl text-xl flex flex-col justify-center items-center">
              <h1>Eles contam como é</h1>
              <h1 className="text-blue-inncash">Automatizar a Gestão Financeira</h1>
            </div>
            <Swiper
              modules={[Autoplay, Pagination, EffectFade]}
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              speed={1000}
              loop={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
            >
              {socialProofList.map((socialProof) => {
                return (
                  <SwiperSlide key={socialProof.id}>
                    <Slider
                      name={socialProof.name}
                      jobPosition={socialProof.jobPosition}
                      profilePicture={socialProof.profilePicture}
                      content={socialProof.content}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </section>

        <section className="mt-10 md:mt-14">
          <div className="bg-blue-inncash rounded-3xl py-10 lg:mx-14 min-[1700px]:mx-40 mx-2 sm:px-14 md:px-0 md:flex items-center justify-center">
            <div className="p-4 md:p-3 lg:px-6 xl:py-6 md:flex justify-between items-center md:w-full">
              <div className="mb-14 xl:mb-0 xl:ml-14 md:mt-8 font-bold text-2xl md:text-3xl xl:text-4xl lg:text-3xl text-white flex flex-col items-center md:items-start md:justify-center">
                <h1>Confira o que o diretor</h1>
                <h1>
                  da <span className="text-blue-200-inncash">Colorminas</span> tem a
                </h1>
                <h1>dizer sobre o InnCash.</h1>
              </div>
              <span className="relative">
                <img
                  src={videoColorminas}
                  alt="Thumbnail vídeo do youtube relato cliente Clayton Schueroff da empresa Colorminas sobre o Inncash"
                  className="relative rounded-2xl md:w-[390px] xl:w-[650px] lg:w-[500px]"
                />
                <img
                  src={playButton}
                  alt="Botão de play branco redondo"
                  onClick={openModalVideoColorminas}
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 cursor-pointer hover:scale-105"
                />
              </span>
            </div>

            <Modal isOpen={modalIsOpenVideoColorminas} onRequestClose={closeModalVideoColorminas} style={modalStyle}>
              <span className="flex justify-end">
                <button onClick={closeModalVideoColorminas}>
                  <MdClose size={23} className="transition duration-150 hover:text-blue-inncash hover:scale-110" />
                </button>
              </span>
              <span className="flex justify-center">
                {screenSize <= 768 ? (
                  <iframe
                    width="370"
                    height="350"
                    src="https://www.youtube.com/embed/vnl4uTr8Dlc"
                    title="O que os Clientes falam do InnCash | Clayton Schueroff (Colorminas)"
                    frameborder="0"
                    allow="autoplay"
                  ></iframe>
                ) : (
                  <iframe
                    width={screenSize <= 1100 ? "790" : "900"}
                    height="550"
                    src="https://www.youtube.com/embed/vnl4uTr8Dlc"
                    title="O que os Clientes falam do InnCash | Clayton Schueroff (Colorminas)"
                    frameborder="0"
                    allow="autoplay"
                  ></iframe>
                )}
              </span>
            </Modal>
          </div>
        </section>

        <section className="mt-10 md:mt-14">
          <div className="flex justify-center">
            <div className="md:w-[775px] md:h-[450px] h-[750px] flex flex-col">
              <div className="flex flex-col items-center xl:text-2xl text-md max-[380px]:text-sm md:text-2xl font-bold mb-8 md:mb-14">
                <h1>
                  Pesquisas da{" "}
                  <a
                    href="https://www.gartner.com.br/pt-br"
                    target="_blank"
                    className="text-blue-inncash"
                    rel="noreferrer"
                  >
                    Gartner
                  </a>{" "}
                  apontam sobre a visão
                </h1>
                <h1>de futuro dos CFOs em relação ao setor financeiro:</h1>
              </div>
              <div className="md:flex justify-between md:mx-16 xl:mx-0">
                <div className="flex flex-col items-center mb-8">
                  <div className="flex flex-col items-center justify-center w-[220px] h-[220px] rounded-full mb-4">
                    <img src={graphic80} alt="Gráfico de 80% em formato de rosca" />
                  </div>
                  <div className="col-span-1 grid place-content-center place-items-center md:text-xl text-lg">
                    <p>Visam implementar o uso</p>
                    <p>
                      da <b>automatização financeira</b> nas
                    </p>
                    <p>empresas nos próximos anos</p>
                  </div>
                </div>
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center justify-center w-[220px] h-[220px] rounded-full mb-4">
                    <img src={graphic50} alt="Gráfico de 80% em formato de rosca" />
                  </div>
                  <div className="col-span-1 grid place-content-center place-items-center md:text-xl text-lg">
                    <p>
                      Querem <b>diminuir processos</b>
                    </p>
                    <p>
                      <b>manuais</b> para elevar a eficiência
                    </p>
                    <p>do setor financeiro</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-10" id="contact-section">
          <ContactForms id="contact-section" />
        </section>
      </main>
    </>
  );
}
