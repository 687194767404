const moduleQuestions = {
  billing: {
    bankSlip: [
      {
        id: 1,
        question: "Quais bancos utilizam ou gostariam de utilizar para boleto?",
        additionalInfo: "",
      },
      {
        id: 2,
        question:
          "Quantas contas utilizam ou gostariam de utilizar para receber com boleto?",
        additionalInfo:
          "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
      },
    ],
    pix: [
      {
        id: 3,
        question:
          "Quais bancos utilizam ou gostariam de utilizar para receber com Pix?",
        additionalInfo: "",
      },
      {
        id: 4,
        question:
          "Quantas contas utilizam ou gostariam de utilizar para receber com pix?",
        additionalInfo:
          "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
      },
    ],
    card: [
      {
        id: 5,
        question:
          "Quais adquirentes (Cielo, Rede, etc) utilizam ou gostariam de utilizar para receber com cartão?",
        additionalInfo: "",
      },
      {
        id: 6,
        question:
          "Quantas contas utilizam ou gostariam de utilizar para cartão?",
        additionalInfo:
          "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
      },
    ],
    directDebit: [
      {
        id: 7,
        question:
          "Quais bancos utilizam ou gostariam de utilizar para receber com Débito Automático?",
        additionalInfo: "",
      },
      {
        id: 8,
        question:
          "Quantas contas utilizam ou gostariam de utilizar para débito automático?",
        additionalInfo:
          "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
      },
    ],
  },

  billingInstructions: [
    {
      id: 9,
      question:
        "Qual a quantidade média mensal de instruções a serem enviados para Boleto?",
      additionalInfo: "",
    },
    {
      id: 10,
      question: "Para quais bancos serão enviadas as instruções?",
      additionalInfo: "",
    },
    {
      id: 11,
      question: "Para quantas contas serão enviadas as instruções?",
      additionalInfo:
        "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
    },
  ],
  payment: [
    {
      id: 12,
      question: "Qual a quantidade média mensal de títulos a pagar?",
      additionalInfo:
        "(Considerar pagamento a fornecedores, tributos e concessionárias. Não considerar pagamentos de folha)?",
    },
    {
      id: 13,
      question: "Quais bancos são utilizados para pagamento?",
      additionalInfo: "",
    },
    {
      id: 14,
      question: "Quantas contas são utilizadas para pagamento?",
      additionalInfo:
        "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
    },
  ],
  statementReconciliation: [
    {
      id: 15,
      question: "Quais bancos são utilizados para conciliação bancária?",
      additionalInfo: "",
    },
    {
      id: 16,
      question: "Quantas contas são utilizadas para conciliação bancária?",
      additionalInfo:
        "(Considerar contas correntes, vinculadas e caucionadas. Não considerar contas de aplicação)",
    },
  ],
  payroll: [
    {
      id: 17,
      question: "Qual a quantidade média mensal de depósitos de folha?",
      additionalInfo:
        "(lembre de considerar também os pagamentos extras, como adiantamentos, férias, rescisões, dentre outros)",
    },
    {
      id: 18,
      question: "Quais bancos utilizam para pagamento de folha?",
      additionalInfo: "",
    },
    {
      id: 19,
      question: "Quantas contas são utilizadas para débito da folha?",
      additionalInfo:
        "(Considerar todas as empresas para cálculo do total de contas. Ex: Empresa X tem 02 contas; Empresa Y tem 04 contas = Total 06 contas)",
    },
  ],
};

export default moduleQuestions;
