import messageIcon from "../../../assets/icons/message.webp";
import linkedInIcon from "../../../assets/icons/linkedin.svg";
import instagramIcon from "../../../assets/icons/instagram.svg";
import youtubeIcon from "../../../assets/icons/youtube.svg";

export default function TokenExpired() {
  return (
    <>
      <section className="mt-[81px]">
        <div className="bg-inncash bg-cover bg-no-repeat lg:h-[585px] md:h-[420px] h-[420px]" />
        <div className="flex flex-col items-center justify-center mt-[-340px] lg:mt-[-650px]">
          <div className="border-2 border-gray-70-inncash w-[400px] max-[380px]:w-[350px] md:w-[800px] lg:w-[1000px] bg-white rounded-3xl py-12 h1 lg:mt-[130px] lg:mb-[60px] mb-[60px]">
            <div className="flex flex-col justify-center items-center">
              <img src={messageIcon} alt="" className="w-36 mb-6" />
              <div className="flex flex-col items-center mb-12">
                <span className="font-bold text-2xl flex flex-col items-center mb-3">
                  <h1>Agradecemos pelo acesso!</h1>
                  <h1>Suas respostas já foram enviadas.</h1>
                </span>
                <p> Em breve, enviaremos a sua proposta.</p>
              </div>
              <div className="flex flex-col items-center">
                <h3 className="font-bold mb-3">Acompanhe nossas redes sociais</h3>
                <div className="flex items-center justify-center">
                  <a href="https://www.linkedin.com/company/inncash/" target="blank">
                    <img src={linkedInIcon} alt="Logotipo do LinkedIn" className="w-[24px] h-[24px] mr-6" />
                  </a>
                  <a href="https://www.instagram.com/inn.cash/" target="blank">
                    <img src={instagramIcon} alt="Logotipo do Instagram" className="w-[24px] h-[24px] mr-6" />
                  </a>
                  <a href="https://www.youtube.com/@inncash8003" target="blank">
                    <img src={youtubeIcon} alt="Logotipo do Youtube" className="w-[29px] h-[24px] mr-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
