import quoteLeftIcon from "../../assets/icons/quote-left.svg";
import quoteRightIcon from "../../assets/icons/quote-right.svg";

export default function Slider({ name, jobPosition, profilePicture, content }) {
  return (
    <div
      key={name}
      className="flex flex-col lg:flex-row md:justify-center items-center xl:h-48 h-58 xl:mt-20 mt-10 mb-12 px-4"
    >
      <div className="flex items-center xl:h-36 xl:mr-6">
        <img
          src={profilePicture}
          alt="Imagem de perfil de um colaborador de um empresa que utiliza o Inncash"
          className="lg:w-[100px] w-[90px] rounded-full"
        />
      </div>
      <div className="flex">
        <div className="flex items-start xl:mr-4">
          <img
            src={quoteLeftIcon}
            alt="Ícone azul de abertura de aspas"
            className="lg:w-[40px] lg:h-[27px] md:w-[30px] md:h-[17] w-[50px] h-[17px] mt-6"
          />
        </div>
        <div className="flex flex-col justify-center p-3 xl:p-0 xl:w-[700px]">
          <p className="text-[14px] md:text-xl lg:mb-7 mb-5 lg:w-[700px]">
            {content}
          </p>
          <p className="text-[15px] md:text-xl font-bold">{name}</p>
          <p className="text-[15px] md:text-xl">{jobPosition}</p>
        </div>
        <div className="mt-32 xl:mb-20 mb-24">
          <img
            src={quoteRightIcon}
            alt="Ícone azul de fechamento de aspas"
            className="lg:w-[40px] lg:h-[27px] md:w-[30px] md:h-[17] w-[50px] h-[17px] mb-2"
          />
        </div>
      </div>
    </div>
  );
}
