import Http from ".";

export default class Repository extends Http {
  isStatusOK(status) {
    return status >= 200 && status < 300;
  }

  setHeader(name, value) {
    const headers = this.client.defaults.headers;
    headers[name] = value;
  }
}
