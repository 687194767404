import { Controller } from "react-hook-form";

export default function Modules({ listModules, selectedModulesContext, control, addModule }) {
  return (
    <>
      <div className="mt-6 flex flex-col">
        {listModules.map((module, index) => {
          const checkboxName = `modules[${index}]`;

          return (
            <span key={index} className="flex items-center mb-6">
              <Controller
                name={checkboxName}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <input
                    id={checkboxName}
                    name={checkboxName}
                    type="checkbox"
                    value={module.name}
                    checked={selectedModulesContext[index]}
                    onClick={addModule}
                    {...field}
                    className="h-[19px] w-[19px] cursor-pointer appearance-none border-[3.7px] rounded-sm transition-all checked:bg-gray-350-inncash"
                  />
                )}
              />
              <label htmlFor={checkboxName} className="ml-1 cursor-pointer">
                {module.name}
              </label>
            </span>
          );
        })}
      </div>
    </>
  );
}
