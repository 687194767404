import Repository from "../../../core/Http/Repository";

export default class VolumetryFormRepository extends Repository {
  uri = "volumetry-form";

  async createOne(dto, path) {
    const { status, data } = await this.post(`${this.uri}${path}`, dto);

    if (this.isStatusOK(status)) return data;
  }

  async getOne(token) {
    const { status, data } = await this.get(`${this.uri}/${token}`);

    if (this.isStatusOK(status)) return data;
  }

  async updateOne(token, dto) {
    const { status } = await this.put(`${this.uri}/${token}`, dto);

    if (this.isStatusOK(status)) return;
  }
}
