const modules = [
  {
    name: "Cobranças",
    module: "billing",
  },
  {
    name: "Instruções para boletos de cobrança",
    module: "billingInstructions",
  },
  {
    name: "Pagamentos",
    module: "payment",
  },
  {
    name: "Conciliação de DDA",
    module: "conciliationDDA",
  },
  {
    name: "Conciliação de Extrato",
    module: "statementReconciliation",
  },
  {
    name: "Folha de Pagamento",
    module: "payroll",
  },
];

export default modules;
