import ContactForms from "../../components/ContactForms/ContactForms";

import imageCreditAnalysis from "../../assets/thumbs/image-credit-analysis.webp";
import creditRulesIcon from "../../assets/icons/credit-rules.svg";
import decisionMakingIcon from "../../assets/icons/decision-making.svg";
import financialSheetIcon from "../../assets/icons/financial-sheet.svg";
import agilityInReleaseIcon from "../../assets/icons/agility-in-release.svg";

export default function CreditAnalysis({ modalStyle, screenSize }) {
  return (
    <>
      <section className="bg-inncash xl:bg-cover bg-no-repeat flex flex-col md:flex-row justify-center md:justify-between md:py-16 py-10 items-center min-[1700px]:px-36 xl:px-20 lg:px-12 md:px-2 md:mt-20 mt-[81px]">
        <div className="flex flex-col md:items-start items-center text-white md:ml-3 xl:ml-0">
          <h1 className="font-bold text-3xl lg:text-5xl min-[1700px]:text-6xl">Análise de</h1>
          <h1 className="font-bold text-4xl lg:text-6xl md:mb-7 mb-4 min-[1700px]:text-7xl">Crédito</h1>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">Potencialize seus negócios com o nosso</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">módulo de análise de crédito, tornando as</p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl">decisões de crédito mais assertivas, </p>
          <p className="text-sm xl:text-lg min-[1700px]:text-xl mb-6">inteligentes e seguras.</p>
          <button className="text-sm md:text-sm min-[1700px]:text-lg font-bold rounded-3xl px-4 py-1 md:px-9 md:py-3 bg-green-500-attention hover:bg-green-500-attention-hover transition duration-150">
            <a href="#contact-section">FALE COM UM ESPECIALISTA</a>
          </button>
        </div>
        <span className="flex md:justify-end justify-center relative mt-8 md:mt-0">
          <img
            src={imageCreditAnalysis}
            alt="Imagem com a tela do Inncash"
            className="rounded-2xl md:w-[450px] lg:w-[550px] xl:w-[650px] w-[340px]"
          />
        </span>
      </section>

      <section className="mt-5 md:mt-12 mx-3">
        {screenSize <= 768 ? (
          <div className="font-bold text-lg flex flex-col justify-center items-center md:mt-6 mb-8">
            <h1>Análise de crédito automatizada</h1>
          </div>
        ) : (
          <div className="md:flex justify-center text-lg mb-8 mt-6 md:mt-0">
            <h1 className="md:text-xl xl:text-2xl font-bold">Análise de crédito automatizada</h1>
          </div>
        )}
        <div className="text-[17px] md:text-xl xl:text-3xl flex flex-col items-center xl:mb-18 md:mb-0">
          <h1>Reunimos todos os dados necessários para que</h1>
          <h1>
            <b>sua decisão seja embasada e confiável</b>
          </h1>
        </div>

        <div className="md:grid grid-cols-2 xl:mx-24 xl:mt-16 max[380px]-mx-2">
          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-14 xl:mt-0">
            <img src={creditRulesIcon} alt="Ícone azul de papael com um checklist" className="w-20 xl:w-22 xl:mt-8" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Regras de crédito</h2>
              <p>Configuramos as regras de crédito com base nas</p>
              <p>informações repassadas por você, deixando os</p>
              <p>critérios de avaliação para liberação ou bloqueio</p>
              <p>personalizados.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-10 xl:mt-0">
            <img src={decisionMakingIcon} alt="Ícone azul de usuário" className="w-18 md:w-16 xl:mt-5" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0 text-sm">
              <h2 className="text-blue-inncash font-bold text-lg max-[380px]:text-[16px] xl:text-xl mb-5">
                Tomada de decisão
              </h2>
              <p>Com as informações demonstradas na ferramenta, </p>
              <p>você consegue fazer uma análise de crédito mais</p>
              <p>efetiva, reduzindo riscos e tomando decisões mais</p>
              <p>consistentes para a aprovação/negação do crédito.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img src={financialSheetIcon} alt="Ícone azul de gráfico de pizza" className="w-18 md:w-16 xl:mt-5" />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Ficha financeira</h2>
              <p>Através dos dados apresentados, você consegue </p>
              <p>analisar de forma detalhada a capacidade financeira</p>
              <p>do seu cliente, por meio de gráficos e informações</p>
              <p>precisas e com segurança.</p>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row items-center xl:items-start md:justify-center mt-16 text-sm">
            <img
              src={agilityInReleaseIcon}
              alt="Ícone azul de dinheiro em moedas e papel"
              className="w-18 md:w-16 xl:mt-5"
            />
            <div className="flex flex-col items-center xl:items-start md:ml-3 xl:ml-6 mt-4 xl:mt-0">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Agilidade na liberação</h2>
              <p>A ferramanta agiliza ao máximo o processo de </p>
              <p>liberação de crédito. Isso significa menos tempo</p>
              <p>de espera para seus clientes, tornando o processo</p>
              <p>de análise de crédito eficiente e eficaz.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 mb-10 flex items-center justify-center">
        <div className="bg-gray-100-inncash py-8 xl:px-36 px-10 rounded-3xl flex flex-col lg:flex-row items-center lg:justify-between justify-center mx-4">
          <div className="flex flex-col items-center lg:items-start text-blue-inncash font-bold text-xl md:text-2xl xl:text-xl mb-4 lg:mb-0 2xl:mr-28 xl:mr-10 lg:mr-8">
            <h1 className="md:text-3xl text-lg lg:text-2xl">Liberamos os pedidos de vendas</h1>
            <h1 className="md:text-3xl text-lg lg:text-2xl">de forma automática para você.</h1>
          </div>
          <p className="lg:w-[400px] lg:text-sm xl:text-normal">
            Já pensou em ter seus pedidos de venda analisados de forma rápida e precisa? Com o nosso módulo de análise
            de crédito isso é possivel! Com base nas regras criadas por você, deixe a ferramenta fazer toda a análise de
            crédito e automatizar o seu processo de liberação dos pedidos.
          </p>
        </div>
      </section>

      <section id="contact-section">
        <ContactForms />
      </section>
    </>
  );
}
