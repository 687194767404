import bancoDoBrasil from "../../../assets/banks/banco-do-brasil.svg";
import itau from "../../../assets/banks/itau.svg";
import caixa from "../../../assets/banks/caixa.svg";
import safra from "../../../assets/banks/safra.svg";
import santander from "../../../assets/banks/santander.svg";
import bradesco from "../../../assets/banks/bradesco.svg";
import sicredi from "../../../assets/banks/sicredi.svg";
import sicoob from "../../../assets/banks/sicoob.svg";
import daycoval from "../../../assets/banks/daycoval.svg";
import banrisul from "../../../assets/banks/banrisul.svg";

export default function BanksMobile() {
  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:gap-8 mb-4">
        <img
          src={bancoDoBrasil}
          alt="Logotipo do Banco do Brasil"
          className="w-40 md:w-52"
        />
        <img src={itau} alt="Logotipo do banco Itaú" className="w-40 md:w-52" />
        <img
          src={caixa}
          alt="Logotipo do banco Caixa"
          className="w-40 md:w-52"
        />
        <img
          src={safra}
          alt="Logotipo do banco Safra"
          className="w-40 md:w-52"
        />
        <img
          src={santander}
          alt="Logotipo do banco Santander"
          className="w-40 md:w-52"
        />
        <img
          src={bradesco}
          alt="Logotipo do banco Bradesco"
          className="w-40 md:w-52"
        />
        <img
          src={sicredi}
          alt="Logotipo do banco Sicred"
          className="w-40 md:w-52"
        />
        <img
          src={sicoob}
          alt="Logotipo do banco Sicoob"
          className="w-40 md:w-52"
        />
        <img
          src={daycoval}
          alt="Logotipo do banco Daycoval"
          className="w-40 md:w-52"
        />
        <img
          src={banrisul}
          alt="Logotipo do banco Banrisul"
          className="w-40 md:w-52"
        />
      </div>
    </>
  );
}
