import Partners from "./Partners";

import opportunityIcon from "../../assets/icons/opportunity.svg";
import salesChannelIcon from "../../assets/icons/sales-channel.svg";
import expansionIcon from "../../assets/icons/expansion.svg";
import profitIcon from "../../assets/icons/profit.svg";
import partnerMarketing from "../../assets/img/partner-marketing.webp";
import partnerEvents from "../../assets/img/partner-events.webp";
import partnerTraining from "../../assets/img/partner-training.webp";
import partnerCommercial from "../../assets/img/partner-commercial.webp";
import PartnerForms from "../../components/PartnerForms";

export default function Partner({ screenSize, partnersList }) {
  return (
    <>
      <section className="bg-partners bg-cover bg-no-repeat lg:h-[585px] md:h-[420px] h-[220px] flex items-center justify-center md:justify-start md:mt-20 mt-20">
        <div className="flex flex-col md:items-start md:ml-14 items-center text-white md:my-12 xl:ml-36">
          <h1 className="font-bold text-3xl lg:text-7xl">Seja um</h1>
          <h1 className="font-bold text-4xl lg:text-8xl md:mb-7 mb-4">Parceiro</h1>
          <p className="text-sm xl:text-2xl">Realize a expansão dos seus negócios</p>
          <p className="text-sm xl:text-2xl">com o Programa de Parceiros InnCash.</p>
        </div>
      </section>

      <section className="md:mt-10 mt-6">
        <div className="font-bold text-lg md:text-3xl flex flex-col items-center">
          <h1>Conheça os benefícios do</h1>
          <h1 className="text-blue-inncash">Programa de Parceirias InnCash</h1>
        </div>

        <div className="flex items-center justify-center">
          <div className="bg-gray-100-inncash py-8 lg:px-20 px-10 mx-4 rounded-3xl flex flex-col items-center justify-center xl:mt-10 mt-6 xl:text-lg md:text-xs text-sm">
            {screenSize <= 770 ? (
              <div className="p-2">
                <p>
                  Desde o início da história do Inncash, levamos ao mercado a melhor solução em serviços financeiros.{" "}
                  <b>Equipes exigentes buscam soluções inovadoras</b> que automatizem os processos diários, com
                  resultados assertivos. A partir daí, conseguem dedicar seu tempo à visão estratégica de seus negócios.
                </p>
                <p className="mt-6">
                  Buscamos atender nossos clientes de forma <b>personalizada</b> e com as melhores soluções em
                  tecnologia.
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <p>
                  Desde o início da história do Inncash, levamos ao mercado a melhor solução em serviços financeiros.{" "}
                  <b>Equipes exigentes</b>
                </p>
                <p>
                  <b>buscam soluções inovadoras</b> que automatizem os processos diários, com resultados assertivos. A
                  partir daí, conseguem
                </p>
                <p>dedicar seu tempo à visão estratégica de seus negócios.</p>

                <p className="mt-6">
                  Buscamos atender nossos clientes de forma <b>personalizada</b> e com as melhores soluções em
                  tecnologia.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="mt-6">
        <div className="md:grid grid-cols-2 place-items-center xl:mx-30 min-[1700px]:mx-48 md:mx-4 mx-3">
          <div className="mt-10 mb-8 xl:flex justify-start items-center xl:w-[480px]">
            <div className="flex flex-col items-center">
              <img
                src={opportunityIcon}
                alt="Ícone azul de globo com flechas em volta"
                className="w-20 xl:w-18 mb-4 xl:mr-6"
              />
            </div>
            <div className="flex flex-col items-center xl:items-start">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Um mundo de oportunidades</h2>
              <p>Demonstrar as oportunidades e os ganhos</p>
              <p>operacionais que a automatização financeira</p>
              <p>proporciona, entregando valor ao cliente.</p>
            </div>
          </div>

          <div className="mt-10 mb-8 xl:flex justify-start items-center xl:w-[480px]">
            <div className="flex flex-col items-center">
              <img src={salesChannelIcon} alt="Ícone azul com 3 bonecos" className="w-20 xl:w-18 mb-4 xl:mr-6" />
            </div>
            <div className="flex flex-col items-center xl:items-start">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Canal de vendas indiretas</h2>
              <p className="max-[380px]:text-sm">Vendas indiretas por meio do nosso time comercial</p>
              <p className="max-[380px]:text-sm">e faturamento direto, o que possibilita parcerias</p>
              <p className="max-[380px]:text-sm">bilaterais.</p>
            </div>
          </div>

          <div className="mt-10 xl:flex justify-start items-center xl:w-[480px]">
            <div className="flex flex-col items-center">
              <img src={expansionIcon} alt="Ícone azul de foguete" className="w-20 xl:w-18 mb-4 xl:mr-6" />
            </div>
            <div className="flex flex-col items-center xl:items-start">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Expansão do portfólio</h2>
              <p className="max-[380px]:text-sm">Aumento do portfólio de ofertas para o parceiro.</p>
            </div>
          </div>

          <div className="mt-10 xl:flex justify-start items-center xl:w-[480px]">
            <div className="flex flex-col items-center">
              <img
                src={profitIcon}
                alt="Ícone azul de círculo com cifrão dentro"
                className="w-20 xl:w-18 mb-4 xl:mr-6"
              />
            </div>
            <div className="flex flex-col items-center xl:items-start">
              <h2 className="text-blue-inncash font-bold text-lg xl:text-xl mb-5">Aumento dos lucros</h2>
              <p>Ganhos financeiros da parceria.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="md:mt-16 mt-14">
        <div className="xl:flex xl:flex-row justify-center xl:gap-5 md:grid grid-cols-2 place-items-center md:mx-14 flex flex-col items-center">
          <div className="mb-8 xl:mb-0">
            <img src={partnerMarketing} alt="Mulher de óculos mexendo em notebook" className="rounded-t-3xl" />
            <div className="text-white bg-blue-inncash w-[308px] h-[200px] flex flex-col items-center py-6 rounded-b-3xl">
              <h1 className="font-bold mb-6">Marketing</h1>
              <p>Materiais sobre o sistema,</p>
              <p>trabalho de divulgação em</p>
              <p>conjunto com parceiros e ações</p>
              <p>de marketing em conjunto.</p>
            </div>
          </div>
          <div className="mb-8 xl:mb-0">
            <img src={partnerEvents} alt="Grupo de pessoas com as mãos unidas" className="rounded-t-3xl" />
            <div className="text-white bg-purple-400-inncash w-[308px] h-[200px] flex flex-col items-center py-6 rounded-b-3xl">
              <h1 className="font-bold mb-6">Eventos</h1>
              <p>Realizamos eventos presenciais</p>
              <p>e online em parceria com</p>
              <p>grandes instituições financeiras</p>
              <p>e grandes empresas.</p>
            </div>
          </div>
          <div className="mb-8 md:mb-0">
            <img src={partnerTraining} alt="Pessoas recebendo treinamento em uma sala" className="rounded-t-3xl" />
            <div className="text-white bg-blue-inncash w-[308px] h-[200px] flex flex-col items-center py-6 rounded-b-3xl">
              <h1 className="font-bold mb-6">Treinamentos</h1>
              <p>Atualizações comerciais e de</p>
              <p>produtos, alinhando discursos e</p>
              <p>como identificar oportunidades.</p>
            </div>
          </div>
          <div className="mb-8 md:mb-0">
            <img
              src={partnerCommercial}
              alt="Dois homens de terno apontando para o notebook com uma caneta"
              className="rounded-t-3xl"
            />
            <div className="text-white bg-purple-400-inncash w-[308px] h-[200px] flex flex-col items-center py-6 rounded-b-3xl">
              <h1 className="font-bold mb-6">Comercial</h1>
              <p>Distribuir e acompanhar com</p>
              <p>nosso time comercial as</p>
              <p>oportunidades oriundas de</p>
              <p>parceiros.</p>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-blue-700-demonstration xl:mt-9 md:mt-8 mx-3 xl:mx-16 min-[1700px]:mx-40 py-3 xl:px-20 md:px-8 font-bold h-[550px] md:h-[530px] lg:h-[330px] rounded-3xl flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="xl:text-xl text-md max-[380px]:text-sm flex flex-col items-center xl:items-start text-white">
          <h2>Se você se identifica com a nossa missão e</h2>
          <h2>quer ser nosso parceiro, entre em contato!</h2>
        </div>
        <div className="mt-2 flex justify-center lg:flex-col lg:w-[600px]">
          <PartnerForms />
        </div>
      </div>

      <section>
        <div className="flex justify-center md:mt-16 mt-10">
          <h1 className="font-bold md:text-3xl text-2xl">
            Nossos <span className="text-blue-inncash">Parceiros</span>
          </h1>
        </div>
        <div className="grid xl:grid-cols-6 grid-cols-2 place-items-center xl:mx-50">
          {partnersList.map((partner) => {
            return <Partners key={partner.id} href={partner.href} src={partner.src} alt={partner.alt} />;
          })}
        </div>
      </section>

      <section className="mt-4">
        <div className="bg-gray-100-inncash h-[250px] md:h-[200px] md:mx-7 xl:mx-[300px] mx-3 rounded-3xl flex flex-col md:items-center justify-center p-2 max-[380px]:text-sm">
          <p>
            Queremos te falar como podemos ajudar o seu negócio a ter <b>mais opções de ofertas em seu</b>
          </p>
          <p>
            <b>portfólio</b> e compor o faturamento da sua empresa com um parceiro como o <b>Inncash</b>!
          </p>
          <p className="mt-6">
            Com a gente, você pode expandir a sua marca, ter acesso a oportunidades exclusivas e ganhar
          </p>
          <p>comissões com o Programa de Indicações.</p>
        </div>
        <div className="flex justify-center mt-12 mb-6 md:text-3xl text-2xl">
          <h1>
            Seja <b>eficiente.</b> Seja <b>InnCash</b>!
          </h1>
        </div>
      </section>
    </>
  );
}
