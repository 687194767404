import banksCardDesktop from "../../../assets/banks/cards-desktop.svg";

export default function BanksDesktop() {
  return (
    <>
      <img
        src={banksCardDesktop}
        alt="Cards de Bancos"
        className="xl:w-[700px] lg:w-[600px]"
      />
    </>
  );
}
