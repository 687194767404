import HubspotForms from "./components/HubspotForms";

export default function ContactForms() {
  return (
    <>
      <div className="bg-blue-inncash py-20 lg:pr-[90px] lg:pl-[100px] xl:pr-[110px] xl:pl-[190px] min-[1700px]:px-[230px] flex flex-col md:flex-row md:justify-between md:items-center xl:py-18 md:p-4 px-3">
        <div className="text-white flex flex-col md:items-start items-center mb-12 mt-6">
          <h1 className="font-bold md:text-4xl xl:text-5xl text-3xl">Seja Eficiente.</h1>
          <h1 className="font-bold md:text-5xl xl:text-6xl text-5xl mb-8">Seja InnCash!</h1>
          <div className="flex items-center md:w-[370px] xl:w-[390px] w-[300px]">
            <p className="text-lg xl:text-xl">
              Descubra como <b>revolucionar</b> o setor financeiro da sua empresa com o <b>InnCash</b>.
            </p>
          </div>
        </div>

        <div className="bg-white px-8 py-2 md:my-4 md:w-[550px] rounded-3xl flex flex-col justify-center">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-lg xl:text-xl mt-5">Entre em contato conosco</h1>
            <h1 className="font-bold text-lg xl:text-xl">para uma demonstração:</h1>
          </div>
          <div className="min-h-[250px]">
            <HubspotForms />
          </div>
        </div>
      </div>
    </>
  );
}
