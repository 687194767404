import { Controller } from "react-hook-form";

export default function BillingQuestions({
  methods,
  control,
  currentModule,
  checkBankSlip,
  checkPix,
  checkCard,
  checkDirectDebit,
  dataRadioButtonBankSlip,
}) {
  let questionNumberBilling = {
    bankSlip: 1,
    pix: 1,
    card: 1,
    directDebit: 1,
  };

  return (
    <>
      {checkBankSlip && (
        <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
          <h3 className="font-bold -mb-5">Cobrança via Boleto</h3>
          {currentModule.questions.bankSlip.map((question, index) => {
            const number = (index + 1).toString();
            const moduleName = "billing";
            const questionName = `${moduleName}.bankSlip.question${number}`;
            const questionError = `question${number}`;
            const bankSlip = "bankSlip";
            const moduleErrors = methods.formState.errors.billing;

            const error = moduleErrors?.[bankSlip]?.[questionError]?.message;

            return (
              <div key={question.id} className="mt-12">
                <h2 className="font-bold">
                  {questionNumberBilling.bankSlip++}.{question.question}
                  {error && <span className="font-bold text-xs text-red-500 ml-2">{error}</span>}
                </h2>
                <p>{question.additionalInfo}</p>
                <Controller
                  name={questionName}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input {...field} type="text" className="border-b-2 border-black w-full focus:outline-none mt-2" />
                  )}
                />
              </div>
            );
          })}
          <div className="mt-12">
            <h2 className="font-bold">
              3.Preferem utilizar boleto convencional (apenas código de barras) ou boleto híbrido
            </h2>
            <span className="flex items-center">
              <h2 className="font-bold ml-3">(código de barras + QR Code de PIX)?</h2>
              {methods.formState.errors.billing?.bankSlip?.question3 && (
                <span className="font-bold text-xs text-red-500 ml-2">
                  {methods.formState.errors.billing?.bankSlip?.question3?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="flex items-center mt-4">
              <Controller
                name="billing.bankSlip.question3"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="radio"
                    id="hybridBankSlipType"
                    value="Boleto híbrido"
                    name="billing.bankSlip.question3"
                    defaultChecked={dataRadioButtonBankSlip === "Boleto híbrido"}
                    className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
                  />
                )}
              />
              <label htmlFor="hybridBankSlipType" className="ml-1">
                Boleto híbrido
              </label>
            </span>
            <span className="flex items-center mt-4">
              <Controller
                name="billing.bankSlip.question3"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    type="radio"
                    id="conventionalBankSlipType"
                    value="Boleto convencional"
                    name="billing.bankSlip.question3"
                    defaultChecked={dataRadioButtonBankSlip === "Boleto convencional"}
                    className="ml-4 h-5 w-5 accent-gray-350-inncash cursor-pointer"
                  />
                )}
              />
              <label htmlFor="conventionalBankSlipType" className="ml-1">
                Boleto convencional
              </label>
            </span>
          </div>
        </div>
      )}

      {checkPix && (
        <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
          <h3 className="font-bold -mb-5">Cobrança via PIX</h3>
          {currentModule.questions.pix.map((question, index) => {
            const number = (index + 1).toString();
            const moduleName = "billing";
            const questionName = `${moduleName}.pix.question${number}`;
            const questionError = `question${number}`;
            const pix = "pix";
            const moduleErrors = methods.formState.errors.billing;

            const error = moduleErrors?.[pix]?.[questionError]?.message;

            return (
              <div key={question.id} className="mt-12">
                <h2 className="font-bold">
                  {questionNumberBilling.pix++}.{question.question}
                  {error && <span className="font-bold text-xs text-red-500 ml-2">{error}</span>}
                </h2>
                <p>{question.additionalInfo}</p>
                <Controller
                  name={questionName}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input {...field} type="text" className="border-b-2 border-black w-full focus:outline-none mt-2" />
                  )}
                />
              </div>
            );
          })}
        </div>
      )}

      {checkCard && (
        <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
          <h3 className="font-bold -mb-5">Cobrança via Cartão (Débito ou Crédito)</h3>
          {currentModule.questions.card.map((question, index) => {
            const number = (index + 1).toString();
            const moduleName = "billing";
            const questionName = `${moduleName}.card.question${number}`;
            const questionError = `question${number}`;
            const card = "card";
            const moduleErrors = methods.formState.errors.billing;

            const error = moduleErrors?.[card]?.[questionError]?.message;

            return (
              <div key={question.id} className="mt-12">
                <h2 className="font-bold">
                  {questionNumberBilling.card++}.{question.question}
                  {error && <span className="font-bold text-xs text-red-500 ml-2">{error}</span>}
                </h2>
                <p>{question.additionalInfo}</p>
                <Controller
                  name={questionName}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input {...field} type="text" className="border-b-2 border-black w-full focus:outline-none mt-2" />
                  )}
                />
              </div>
            );
          })}
        </div>
      )}

      {checkDirectDebit && (
        <div className="border-2 border-gray-70-inncash rounded-3xl p-6 mt-5">
          <h3 className="font-bold -mb-5">Cobrança via Débito Automático</h3>
          {currentModule.questions.directDebit.map((question, index) => {
            const number = (index + 1).toString();
            const moduleName = "billing";
            const questionName = `${moduleName}.directDebit.question${number}`;
            const questionError = `question${number}`;
            const directDebit = "directDebit";
            const moduleErrors = methods.formState.errors.billing;

            const error = moduleErrors?.[directDebit]?.[questionError]?.message;

            return (
              <div key={question.id} className="mt-12">
                <h2 className="font-bold">
                  {questionNumberBilling.directDebit++}.{question.question}
                  {error && <span className="font-bold text-xs text-red-500 ml-2">{error}</span>}
                </h2>
                <p>{question.additionalInfo}</p>
                <Controller
                  name={questionName}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input {...field} type="text" className="border-b-2 border-black w-full focus:outline-none mt-2" />
                  )}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
