import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import About from "./pages/About";
import Home from "./pages/Home/Home";
import Partner from "./pages/Partner/Partner";
import Payment from "./pages/Solutions/Payment";
import Billing from "./pages/Solutions/Billing";
import BankConciliation from "./pages/Solutions/BankConciliation";
import CashFlow from "./pages/Solutions/CashFlow";
import VolumetryForm from "./pages/VolumetryForm/VolumetryForm";
import TokenExpired from "./pages/VolumetryForm/components/TokenExpired";
import CreditAnalysis from "./pages/Solutions/CreditAnalysis";
import PaymentGateway from "./pages/Solutions/PaymentGateway";

import sesc from "./assets/clients/sesc.webp";
import barbosaMello from "./assets/clients/barbosa-mello.webp";
import colorminas from "./assets/clients/colorminas.webp";
import emtel from "./assets/clients/emtel.webp";
import ftc from "./assets/clients/ftc.webp";
import gts from "./assets/clients/gts.webp";
import hipperFreios from "./assets/clients/hipper-freios.webp";
import kfg from "./assets/clients/kfg.webp";
import plasson from "./assets/clients/plasson.webp";
import portonave from "./assets/clients/portonave.webp";
import rech from "./assets/clients/rech.webp";
import schumann from "./assets/clients/schumann.webp";
import specialDog from "./assets/clients/specialdog.png";
import unoesc from "./assets/clients/unoesc.webp";
import bancoDoBrasil from "./assets/partners/banco-do-brasil.svg";
import santander from "./assets/partners/santander.svg";
import neogrid from "./assets/partners/neogrid.svg";
import gruppeTecnologia from "./assets/partners/gruppe-tecnologia.svg";
import personalitec from "./assets/partners/personalitec.svg";
import ebury from "./assets/partners/ebury.svg";
import fabianaPeres from "./assets/socialProof/fabiana-peres.webp";
import valeriaSousa from "./assets/socialProof/valeria-sousa.webp";
import danielyVolpato from "./assets/socialProof/daniely-volpato.webp";
import claytonSchueroff from "./assets/socialProof/clayton-schueroff.webp";

const screenSize = window.screen.width;

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(54, 54, 54, 0.73)",
    zIndex: 1000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "1050px",
    borderRadius: "10px",
  },
};

const customersList = [
  {
    id: 1,
    href: "https://www.specialdog.com.br/",
    src: specialDog,
    alt: "Special Dog",
  },
  {
    id: 2,
    href: "https://www.sescrio.org.br/",
    src: sesc,
    alt: "Sesc Rio de Janeiro",
  },
  {
    id: 3,
    href: "http://www.cbmsa.com.br/construtora",
    src: barbosaMello,
    alt: "Construtora Barbosa Mello",
  },
  {
    id: 4,
    href: "https://www.colorminas.com.br/",
    src: colorminas,
    alt: "Colorminas",
  },
  {
    id: 5,
    href: "https://grupoemtel.com.br/",
    src: emtel,
    alt: "Grupo Emtel",
  },
  {
    id: 6,
    href: "https://www.ftc.com.br/",
    src: ftc,
    alt: "Ferrovia Tereza Cristina",
  },
  {
    id: 7,
    href: "https://www.gtsdobrasil.ind.br/",
    src: gts,
    alt: "GTS",
  },
  {
    id: 8,
    href: "https://www.hipperfreios.com.br/",
    src: hipperFreios,
    alt: "Hipper Freios",
  },
  {
    id: 9,
    href: "https://kfgdistribuidora.com.br/",
    src: kfg,
    alt: "KFG Distribuidora",
  },
  {
    id: 10,
    href: "https://www.plasson.com.br/",
    src: plasson,
    alt: "Plasson",
  },
  {
    id: 11,
    href: "https://www.portonave.com.br/pt/",
    src: portonave,
    alt: "Portonave",
  },
  {
    id: 12,
    href: "https://www.rech.com/",
    src: rech,
    alt: "Rech",
  },
  {
    id: 13,
    href: "https://www.schumann.com.br/",
    src: schumann,
    alt: "Schumann",
  },
  {
    id: 14,
    href: "https://www.unoesc.edu.br/",
    src: unoesc,
    alt: "Unoesc",
  },
];

const partnersList = [
  {
    id: 1,
    href: "https://www.bb.com.br/",
    src: bancoDoBrasil,
    alt: "Banco do Brasil",
  },
  {
    id: 2,
    href: "https://www.santander.com.br/",
    src: santander,
    alt: "Santander",
  },
  {
    id: 3,
    href: "https://neogrid.com/br",
    src: neogrid,
    alt: "Neogrid",
  },
  {
    id: 4,
    href: "https://gruppe.com.br/",
    src: gruppeTecnologia,
    alt: "Gruppe Tecnologia",
  },
  {
    id: 5,
    href: "http://www.personalitec.com.br/",
    src: personalitec,
    alt: "Persosalitec",
  },
  {
    id: 6,
    href: "https://br.ebury.com/",
    src: ebury,
    alt: "Ebury",
  },
];

const socialProofList = [
  {
    id: 1,
    name: "Fabiana Peres",
    jobPosition: "Analista Financeira Inplac",
    profilePicture: fabianaPeres,
    content:
      "Agilizou bastante o nosso processo, reduzimos em 90% o tempo destinado às rotinas financeiras e podemos focar em melhorias para empresa.",
  },
  {
    id: 2,
    name: "Valéria de Sousa Rosário",
    jobPosition: "Analista Financeira da Portonave",
    profilePicture: valeriaSousa,
    content:
      "Estamos muito satisfeitos com a utilização do sistema InnCash, pois vem nos auxiliando na tomada de decisões e agregando assim em melhores resultados para a empresa.",
  },
  {
    id: 3,
    name: "Daniely Volpato",
    jobPosition: "Analista Financeira da Itacorda",
    profilePicture: danielyVolpato,
    content:
      "O sistema é muito simples e traz facilidade para nosso dia-a-dia, e ainda conta com uma equipe de ponta, com um atendimento humano, rápido e personalizado.",
  },
  {
    id: 4,
    name: "Clayton Schueroff",
    jobPosition: "Diretor da Colorminas",
    profilePicture: claytonSchueroff,
    content:
      "Ter a governança e o controle do que a empresa tem a pagar e receber é muito importante. O InnCash é perfeito para esses controles.",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: (
          <Home
            customersList={customersList}
            socialProofList={socialProofList}
            modalStyle={modalStyle}
            screenSize={screenSize}
          />
        ),
      },
      {
        path: "quem-somos",
        element: <About customersList={customersList} screenSize={screenSize} />,
      },
      {
        path: "solucoes-em-pagamentos",
        element: <Payment modalStyle={modalStyle} screenSize={screenSize} />,
      },
      {
        path: "solucoes-em-cobrancas",
        element: <Billing modalStyle={modalStyle} screenSize={screenSize} />,
      },
      {
        path: "solucoes-para-conciliacao-bancaria",
        element: <BankConciliation modalStyle={modalStyle} screenSize={screenSize} />,
      },
      {
        path: "solucoes-para-fluxo-de-caixa",
        element: <CashFlow modalStyle={modalStyle} screenSize={screenSize} />,
      },
      {
        path: "analise-de-credito",
        element: <CreditAnalysis screenSize={screenSize} />,
      },
      {
        path: "gateway-de-pagamento",
        element: <PaymentGateway screenSize={screenSize} />,
      },
      {
        path: "seja-um-parceiro",
        element: <Partner screenSize={screenSize} partnersList={partnersList} />,
      },
      {
        path: "novo-formulario/interno",
        element: <VolumetryForm screenSize={screenSize} />,
      },
      {
        path: "novo-formulario",
        element: <VolumetryForm screenSize={screenSize} />,
      },
      {
        path: "/novo-formulario/token/:token?",
        element: <VolumetryForm screenSize={screenSize} />,
      },
      {
        path: "/novo-formulario/interno/token/:token?",
        element: <VolumetryForm screenSize={screenSize} />,
      },
      {
        path: "/novo-formulario/token-expirado",
        element: <TokenExpired />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
