export default function Customers({ href, src, alt }) {
  return (
    <a
      key={href}
      target="_blank"
      href={href}
      className="hover:shadow-lg ease-in duration-300"
      rel="noreferrer"
    >
      <img
        src={src}
        alt={alt}
        className="mx-auto xl:w-[150px] xl:h-[150px] lg:w-[130px] lg:h-[130px] md:w-[180px] md:h-[180px] w-[120px] h-[120px]"
      />
    </a>
  );
}
