import { useContext } from "react";
import { useLocation } from "react-router-dom";

import ButtonVolumetryForm from "../../../contexts/ButtonVolumetryForm";

import messageIcon from "../../../assets/icons/message.webp";
import linkedInIcon from "../../../assets/icons/linkedin.svg";
import instagramIcon from "../../../assets/icons/instagram.svg";
import youtubeIcon from "../../../assets/icons/youtube.svg";

export default function Acknowledgment() {
  const { shareLink } = useContext(ButtonVolumetryForm);
  const locationPath = useLocation();

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <img src={messageIcon} alt="" className="w-36 mb-6" />
        <div className="flex flex-col items-center mb-12">
          <span className="font-bold text-2xl flex flex-col items-center mb-3">
            <h1>Agradecemos pelo envio</h1>
            <h1>de suas informações.</h1>
          </span>
          <p>Sua resposta foi registrada com sucesso.</p>
          <p> Em breve, enviaremos a sua proposta.</p>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="font-bold mb-3">Acompanhe nossas redes sociais</h3>
          <div className="flex items-center justify-center">
            <a href="https://www.linkedin.com/company/inncash/" target="blank">
              <img src={linkedInIcon} alt="Logotipo do LinkedIn" className="w-[24px] h-[24px] mr-6" />
            </a>
            <a href="https://www.instagram.com/inn.cash/" target="blank">
              <img src={instagramIcon} alt="Logotipo do Instagram" className="w-[24px] h-[24px] mr-6" />
            </a>
            <a href="https://www.youtube.com/@inncash8003" target="blank">
              <img src={youtubeIcon} alt="Logotipo do Youtube" className="w-[29px] h-[24px] mr-6" />
            </a>
          </div>
          <span
            className={
              locationPath.pathname !== "/novo-formulario/interno" ? "hidden" : "flex flex-col items-center mt-12"
            }
          >
            <div className="mb-3 font-bold">Link de Compartilhamento</div>
            <a
              href={shareLink}
              target="_blank"
              className="hover:scale-105 hover:text-blue-inncash transition duration-150 cursor-pointer"
              rel="noreferrer"
            >
              {shareLink}
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
