import { useEffect } from "react";

export default function PartnerForms() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.innerHTML = `
      hbspt.forms.create({
        region: "na1",
        portalId: "40821606",
        formId: "56d70844-beb7-43ff-80f4-74e69a439c11",
        target: "#hubspotForm",
      });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className="mt-4">
      <div id="hubspotForm"></div>
    </section>
  );
}
